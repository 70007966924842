<template>
  <div class="row h-100 overflow-auto">
    <div class="col-12 h-100 overflow-auto">

      <div class="row p-5 border-bottom bg-light">
        <div class="col-12 text-center">
          <h1>Fonds de caisse à {{ user.store.name }}</h1>
        </div>
      </div>

      <!-- Champ pour le montant total calculé -->
      <div class="row p-5 border-bottom">
        <div class="col-auto text-center mx-auto">
          <label for="totalAmount" class="form-label">Montant total calculé</label>
          <div class="input-group mb-3">
            <input type="number" :value="totalAmount" id="totalAmount" class="form-control" readonly disabled>
            <span class="input-group-text">€</span>
          </div>
        </div>
      </div>

      <!-- Section pour les pièces de centimes -->
      <div class="row p-5 border-bottom">
        <div v-for="(value, index) in centimes" :key="index" class="col w-10 text-center me-5">
          <label :for="'unit-' + index" class="form-label">{{ value.label }}</label>
          <div class="input-group mb-3">
            <input type="number" v-model="value.count" :id="'unit-' + index" class="form-control" min="0">
            <span class="input-group-text">pièces</span>
          </div>
        </div>
      </div>

      <!-- Section pour les pièces de 1 et 2 euros -->
      <div class="row p-5 border-bottom">
        <div v-for="(value, index) in euros" :key="index" class="col w-10 text-center me-5">
          <label :for="'unit-' + index" class="form-label">{{ value.label }}</label>
          <div class="input-group mb-3">
            <input type="number" v-model="value.count" :id="'unit-' + index" class="form-control" min="0">
            <span class="input-group-text">pièces</span>
          </div>
        </div>
      </div>

      <!-- Section pour les billets -->
      <div class="row p-5 border-bottom">
        <div v-for="(value, index) in billets" :key="index" class="col w-10 text-center me-5">
          <label :for="'unit-' + index" class="form-label">{{ value.label }}</label>
          <div class="input-group mb-3">
            <input type="number" v-model="value.count" :id="'unit-' + index" class="form-control" min="0">
            <span class="input-group-text">billets</span>
          </div>
        </div>
      </div>

      <div class="row p-5">
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-primary">Enregistrer le fond de caisse</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      centimes: [
        { label: '1 centime', value: 0.01, count: 0 },
        { label: '5 centimes', value: 0.05, count: 0 },
        { label: '10 centimes', value: 0.10, count: 0 },
        { label: '20 centimes', value: 0.20, count: 0 },
        { label: '50 centimes', value: 0.50, count: 0 },
      ],
      euros: [
        { label: '1 euro', value: 1.00, count: 0 },
        { label: '2 euros', value: 2.00, count: 0 },
      ],
      billets: [
        { label: '5 euros', value: 5.00, count: 0 },
        { label: '10 euros', value: 10.00, count: 0 },
        { label: '20 euros', value: 20.00, count: 0 },
        { label: '50 euros', value: 50.00, count: 0 },
        { label: '100 euros', value: 100.00, count: 0 },
        { label: '200 euros', value: 200.00, count: 0 },
        { label: '500 euros', value: 500.00, count: 0 },
      ],
    };
  },
  computed: {
    ...mapGetters('cachier', ['cachier']),
    ...mapGetters('user', ['user']),
    totalAmount() {
      const centimes = this.centimes.reduce((total, unit) => total + (unit.value * unit.count), 0);
      const euros = this.euros.reduce((total, unit) => total + (unit.value * unit.count), 0);
      const billets = this.billets.reduce((total, unit) => total + (unit.value * unit.count), 0);
      return centimes + euros + billets;
    }
  },
  methods: {
    ...mapActions('cachier', ['fetchCashier', 'insertCashier', 'clearCashier']),
    ...mapActions('user', ['fetchUser']),
    submitCashier() {
      const cashierData = {
        centimeUnits: this.centimeUnits,
        euroUnits: this.euroUnits,
        billetUnits: this.billetUnits,
        totalAmount: this.totalAmount,
      };
      this.insertCashier(cashierData);
    }
  },
  mounted() {
    this.fetchUser();
  },
};
</script>
