<template>
  <div class="modal fade" id="addLocationModal" tabindex="-1" aria-labelledby="addLocationModal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addLocationModal">Ajouter un meuble à {{ store.name }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="row g-3 align-items-center" @submit.prevent="enterSubmit">

            <div class="col-6">
              <label for="code" class="form-label">Code meuble</label>
              <input type="text" class="form-control" id="code" v-model="code" maxlength="2" required pattern="[A-Z]{2}" placeholder="AA" ref="focus">
            </div>
            <div class="col-6">
              <label for="code" class="form-label">Numéro</label>
              <input type="text" class="form-control" id="number" v-model="number" maxlength="2" required pattern="[0-9]{2}" placeholder="01" ref="focus">
            </div>

            <div class="col-12">
              <label for="code" class="form-label">Description</label>
              <input type="text" class="form-control" id="description" v-model="description" placeholder="Aphrodisiaques">
            </div>

            <div class="col-auto ms-auto">
              <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Ajouter le meuble</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      code: '',
      number: '',
      description: '',
    };
  },
  methods: {
    enterSubmit() {
      this.addLocation({
        code: this.code,
        number: this.number,
        description: this.description,
      });
    },
    addLocation(location) {
      this.$emit('add-location', location);
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.focus.focus();
      });
    }
  },
  mounted() {
    this.focusInput();
  },
};
</script>