<template>
  <div class="modal fade" id="editCarrierModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="editCarrierModal" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title">{{ carrier ? 'Sélectionner un point relais' : 'Modes de livraison disponibles' }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">

          <div v-if="!carrier" class="row g-3 align-items-center">
            <table class="table table-striped text-start align-middle">
              <thead>
                <tr>
                  <th>Transporteur</th>
                  <th>Mode de livraison</th>
                  <th>Date de livraison estimée</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(carrier, index) in carriers" :key="index">
                  <td>{{ carrier.name }}</td>
                  <td>{{ carrier.mode }}</td>
                  <td>-</td>
                  <td class="text-center">
                    <button v-if="carrier.id_carrier_mode_wms == 2" type="button" class="btn btn-primary btn-sm" @click="updateCarrier(carrier)">Choisir un point relais</button>
                    <button v-else type="button" class="btn btn-success btn-sm" @click="updateCarrier(carrier)" data-bs-dismiss="modal">Séléctionner</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-else class="row g-3 h-100 align-items-center">
            <div v-if="carrier && carrier.id_carrier_mode_wms == 2" class="col-12 h-100">
              <input type="hidden" id="relayPoint">
              <div id="carrier-widget-container">
                <loader />
              </div>
            </div>
          </div>

        </div>

        <div v-if="carrier && carrier.id_carrier_mode_wms == 2" class="modal-footer">
          <button type="button" class="btn btn-primary" @click="clearCarrier">Choisir un autre transporteur</button>
          <button type="button" class="btn btn-success" :disabled="!confirm">{{ confirm ? 'Appliquer ce point relais' : 'Veuillez séléctionner un point relais' }}</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import backend from '@/services/backendService';

export default {
  props: {
    carriers: {
      type: Array, 
      required: true,
      default: () => ([]),
    },
  },
  data() {
    return {
      carrier: null,
      confirm: false,
      token: '',
    };
  },
  computed: {
    ...mapGetters('order', [
      'order'
    ]),
  },
  methods: {
    ...mapActions('order', [
      'updateOrderShippingMethod'
    ]),
    ...mapActions('preparation', [
      'updatePreparationCarrier'
    ]),
    displayConfirmButton() {
      this.confirm = true;
    },
    clearCarrier() {
      this.carrier = null;
      this.$nextTick(() => {
      const container = document.getElementById('carrier-widget-container');
      if (container) {
        container.innerHTML = '';
      }
    });
    },
    updateCarrier(carrier) {
      this.carrier = carrier;
      if (carrier.id_carrier_mode_wms == 2) {
        if (carrier.id_carrier_wms == 5) {
          this.loadColissimoWidget();
        }
        if (carrier.id_carrier_wms == 14) {
          this.initializeMondialRelayWidget();
        }
      } else {
        // this.updateOrderShippingMethod(carrier);
        this.updatePreparationCarrier(carrier);
      }
    },
    updateRelayPoint(relayPoint) {
      console.log(relayPoint);
      // Logique de gestion après la sélection du point relais
    },
    async loadColissimoWidget() {
      try {
        const response = await backend.get('/config/colissimo-token');
        this.token = response.token;
        if (this.token) {
          this.initializeColissimoWidget();
        } else {
          console.error('Échec de la récupération du token Colissimo.');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du token Colissimo:', error);
      }
    },
    initializeColissimoWidget() {
      window.updateRelayPoint = this.updateRelayPoint;
      window.$('#carrier-widget-container').frameColissimoClose();
      window.$('#carrier-widget-container').frameColissimoOpen({
        "URLColissimo": 'https://ws.colissimo.fr',
        "callBackFrame": 'updateRelayPoint',
        "ceCountry": this.order.last_shipping_assignment.shipping.address.country_id,
        "ceAddress": this.order.last_shipping_assignment.shipping.address.street[0],
        "ceZipCode": this.order.last_shipping_assignment.shipping.address.postcode,
        "ceTown": this.order.last_shipping_assignment.shipping.address.city,
        "token": this.token
      });
    },
    initializeMondialRelayWidget() {
      window.$(document).ready(() => {
        window.$("#carrier-widget-container").MR_ParcelShopPicker({
          OnParcelShopSelected: (data) => {
            this.updateRelayPoint(data);
          },
          Target: "#relayPoint",
          Brand: "F1BELISO",
          Theme: "mondialrelay",
          NbResults: 15,
          Responsive: true,
          MapScrollWheel: true,
          EnableGeolocalisatedSearch: false,
          Country: this.order.last_shipping_assignment.shipping.address.country_id,
          PostCode: this.order.last_shipping_assignment.shipping.address.postcode,
          City: this.order.last_shipping_assignment.shipping.address.city,
        });
      });  
    },
    loadExternalScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    async loadScripts() {
      try {
        await this.loadExternalScript("https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.js");
        await this.loadExternalScript("https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.js");
        await this.loadExternalScript("https://unpkg.com/leaflet/dist/leaflet.js");
        await this.loadExternalScript("https://ws.colissimo.fr/widget-colissimo/js/jquery.plugin.colissimo.min.js");
        await this.loadExternalScript("https://widget.mondialrelay.com/parcelshop-picker/jquery.plugin.mondialrelay.parcelshoppicker.min.js");
      } catch (error) {
        console.error('Erreur lors du chargement des scripts externes:', error);
      }
    }
  },
  mounted() {
    this.loadScripts();
  },
};
</script>

<style scoped>
@import "https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.css";
@import "https://unpkg.com/leaflet/dist/leaflet.css";

.widget_colissimo_contenu {
  width: 100% !important;
}
</style>
