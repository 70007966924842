import backend from './backendService';

class StoreService {
  async getStores() {
    return backend.get(`/stores`);
  }

  async getStoreUsers(store_id) {
    return backend.get(`/stores/${store_id}/users`);
  }

  async getStoreLocations(id_store) {
    return backend.get(`/stores/${id_store}/locations`);
  }

  async getStoreLocationShelves(id_store, id_location) {
    return backend.get(`/stores/${id_store}/locations/${id_location}`);
  }

  async addStoreLocation(id_store, location) {
    return backend.post(`/stores/${id_store}/locations`, location);
  }
}

export default new StoreService();
