<template>
  <div class="col border p-3" v-if="loading">
    Chargement...
  </div>
  <div class="col border" v-else>
    <div class="row align-items-center" @click="addProduct(productData)">
      <div class="col product-image" v-if="productData.media_gallery_entries && productData.media_gallery_entries.length > 0">
        <img class="img-fluid" :src="productImageUrl(productData.media_gallery_entries[0].file)" alt="Product Image">
      </div>
    </div>
    <div class="row align-items-center mt-2 px-2">
      <div class="col product-name text-center">{{ productData.name }}</div>
    </div>
    <div class="row justify-space-between mb-2 px-3">
      <div class="col product-price text-start">{{ round(productData.price, 2) }} €</div>
      <div class="col-3 product-info text-end">
        <i class="bi bi-info-circle" title="Associer à un client" :data-bs-target="'#viewProductModal-' + productData.sku" data-bs-toggle="modal"></i>
      </div>
    </div>
  </div>

  <view-product-modal :product="product" />

</template>

<script>
import { round, productImageUrl } from '@/utils/tools';
import ViewProductModal from '@/components/modals/ViewProductModal';
import productService from '@/services/productService';

export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      loading: true,
    };
  },
  components: {
    ViewProductModal,
  },
  computed: {
    productData() {
      return this.data || this.product;
    },
  },
  async mounted() {
    if (!this.product.media_gallery_entries) {
      try {
        const response = await productService.getProduct(this.product.sku);
        this.data = response;
      } catch (error) {
        console.error('Erreur lors de la récupération des informations du produit :', error);
      } finally {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  },
  methods: {
    productImageUrl,
    round,
    addProduct(product) {
      const sku = product.sku;
      const price = product.price;
      this.$emit('add-product', { sku, price });
    },
  },
};
</script>

<style scoped>
@media (max-width: 1200px) {
  .product-image {
    display: none;
  }
}
.product-name {
  min-height: 48px;
  font-size: 14px;
  line-height: normal;
}
.product-price {
  font-weight: 700;
}
</style>
