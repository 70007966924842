<template>
  <div v-if="cart.customer && cart.customer.email" class="row h-64 d-flex justify-content-between align-items-center border-bottom bg-light">
    <div class="col">{{ cart.customer.firstname }} {{ cart.customer.lastname }}<br/>
      <span class="fs-sm">{{ cart.customer.email }}</span>
    </div>
    <div class="col-auto align-items-center">
      <i class="bi bi-person-x fs-2" role="button" title="Dissocier de ce client"></i>
    </div>
  </div>
  <div v-else class="row h-64 d-flex justify-content-between align-items-center border-bottom bg-light">
    <div class="col-1 align-items-center me-2">
      <i class="bi bi-person-add fs-2" role="button" title="Associer à un client" data-bs-target="#addCustomerModal" data-bs-toggle="modal"></i>
    </div>
    <div class="col align-items-center">Client : Anonyme</div>
  </div>
  <add-customer-modal :cart="cart" @add-customer="addCustomer" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddCustomerModal from '@/components/modals/AddCustomerModal.vue';

export default {
  components: {
    AddCustomerModal,
  },
  computed: {
    ...mapGetters('cart', ['cart']),
  },
  methods: {
    ...mapActions('cart', ['addCustomer']),
  }
};
</script>