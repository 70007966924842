import storeService from '@/services/storeServices';

export default {
  namespaced: true,
  state: {
    stores: [],
    store: null,
  },
  mutations: {
    SET_STORES(state, stores) {
      state.stores = stores;
    },
    SET_STORE(state, store) {
      state.store = store;
    },
    SET_STORE_USERS(state, users) {
      state.store = { ...state.store, users };
    },
    SET_STORE_LOCATIONS(state, locations) {
      state.store = { ...state.store, locations };
    },
    SET_STORE_LOCATION(state, location) {
      state.store = { ...state.store, location };
    },
    SET_STORE_LOCATION_SHELVES(state, shelves) {
      state.store.location = { ...state.store.location, shelves };
    },
  },
  actions: {
    async fetchStores({ commit }) {
      try {
        commit('SET_STORES', await storeService.getStores());
      } catch (error) {
        console.error(error);
      }
    },
    async fetchStore({ state, commit }, store) {
      try {
        commit('SET_STORE', store);
        commit('SET_STORE_LOCATIONS', await storeService.getStoreLocations(state.store.id_store));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchStoreUsers({ commit }, store_id) {
      try {
        commit('SET_STORE_USERS', await storeService.getStoreUsers(store_id));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchStoreLocation({ state, commit }, location) {
      try {
        commit('SET_STORE_LOCATION', location);
        commit('SET_STORE_LOCATION_SHELVES', await storeService.getStoreLocationShelves(state.store.id_store, state.store.location.id_location));
      } catch (error) {
        console.error(error);
      }
    },
    async addStoreLocation({ state, dispatch }, location) {
      try {
        await storeService.addStoreLocation(state.store.id_store, location);
        dispatch('fetchStore', state.store);
      } catch (error) {
        console.error(error);
      }
    }
  },
  getters: {
    stores: state => state.stores,
    store: state => state.store,
  }
};
