<template>
  <div class="col d-flex flex-column h-100">
    <div class="row flex-grow-1 overflow-y-auto h-100">

      <!-- Facture détaillée -->
      <div v-if="invoiceFormat == 'invoice'" class="col-12 py-3">
        <div class="row mb-3">
          <div class="col-12">
            <div class="row mb-1">
              <div class="col-12 fw-bold">Facture #{{ data.increment_id }}</div>
            </div>
            <div class="row row-cols">
              <div class="col-12">Commande #{{ data.order_id }}</div>
              <div class="col-12">Date de la commande : {{ formatDate(data.created_at).date }}</div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <div class="row mb-1">
              <div class="col-12 fw-bold">
                Facturation
              </div>
            </div>
            <div class="row row-cols">
              <div class="col-12">{{ order.billing_address.firstname }} {{ order.billing_address.lastname }}</div>
              <div v-for="(street, index) in order.billing_address.street" :key="index" class="col-12">{{ street }}</div>
              <div class="col-12">{{ order.billing_address.postcode }}, {{ order.billing_address.city }}, {{ order.billing_address.region }}</div>
              <div class="col-12">{{ order.billing_address.country_id }}</div>
            </div>
          </div>

          <div class="col-6">
            <div class="row mb-1">
              <div class="col-12 fw-bold">
                 Livraison
              </div>
            </div>
            <div class="row row-cols">
              <div class="col-12">{{ order.last_shipping_assignment.shipping.address.firstname }} {{ order.last_shipping_assignment.shipping.address.lastname }}</div>
              <div v-for="(street, index) in order.last_shipping_assignment.shipping.address.street" :key="index" class="col-12">{{ street }}</div>
              <div class="col-12">{{ order.last_shipping_assignment.shipping.address.postcode }}, {{ order.last_shipping_assignment.shipping.address.city }}, {{ order.last_shipping_assignment.shipping.address.region }}</div>
              <div class="col-12">{{ order.last_shipping_assignment.shipping.address.country_id }}</div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <div class="row mb-1">
              <div class="col-12 fw-bold">
                Mode de paiement
              </div>
            </div>
            <div class="row row-cols">
              <div class="col-12">{{ order.extension_attributes.payment_additional_info[0].value }}</div>
            </div>
          </div>

          <div class="col-6">
            <div class="row mb-1">
              <div class="col-12 fw-bold">
                Méthode de livraison
              </div>
            </div>
            <div class="row row-cols">
              <div class="col-12">{{ order.shipping_description }}</div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <table class="table">
              <thead>
                <tr>
                  <th class="px-0">Produits</th>
                  <th class="px-0">SKU</th>
                  <th class="px-0">Prix</th>
                  <th class="px-0">Quantité</th>
                  <th class="px-0">Taxe</th>
                  <th class="px-0">Sous-total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in data.items" :key="index">
                  <th class="px-0">{{ product.name }}</th>
                  <td class="px-0">{{ product.sku }}</td>
                  <td class="px-0">{{ product.base_price_incl_tax }} €</td>
                  <td class="px-0">{{ product.qty }}</td>
                  <td class="px-0">{{ product.base_tax_amount }} €</td>
                  <td class="px-0">{{ product.base_row_total_incl_tax }} €</td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="fw-bold">
                  <td class="text-end ps-0" colspan="5">Sous-total :</td>
                  <td class="px-0">{{ data.base_subtotal }} €</td>
                </tr>
                <tr class="fw-bold">
                  <td class="text-end ps-0" colspan="5">Taxe :</td>
                  <td class="px-0">{{ data.base_tax_amount }} €</td>
                </tr>
                <tr class="fw-bold">
                  <td class="text-end ps-0" colspan="5">Frais de port :</td>
                  <td class="px-0">{{ data.base_shipping_incl_tax }} €</td>
                </tr>
                <tr class="fw-bold">
                  <td class="text-end ps-0" colspan="5">Montant total : </td>
                  <td class="px-0">{{ data.base_grand_total }} €</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

      <!-- Ticket de Caisse -->
      <div v-else-if="invoiceFormat == 'receipt'" class="col-4 fs-sm text-center border-end p-5">
        <div class="row mb-4">
          <div class="col-12">
            <img class="img-fluid" :src="require('@/assets/img/logos/espaceplaisir-condensed-red.png')" alt="Logo Espaceplaisir" />
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-12">
            {{ order.billing_address.street[0] }}<br>
            {{ order.billing_address.postcode }} {{ order.billing_address.city }}
          </div>
        </div>

        <div class="row border-top border-bottom border-black mb-4">
          <div class="col-12 px-0 py-3">
            <div class="row text-start">
              <div class="col-6">
                <strong>Date : </strong>{{ order.date_created }}<br>
                <strong>Vente de : </strong>{{ order.date_created }}<br>
              </div>
              <div class="col-6">
                <strong>Cmd : </strong> #{{ order.entity_id }}<br>
                <strong>Mode : </strong>{{ order.extension_attributes.payment_additional_info[0].value }}<br>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 px-0">
            <table class="table mb-0">
              <thead class="border-bottom border-black">
                <tr>
                  <th class="text-start px-0">Produits</th>
                  <th class="text-end px-0">Montant</th>
                </tr>
              </thead>
              <tbody class="border-0">
                <tr v-for="(product, index) in data.items" :key="index" class="border-0">
                  <td class="border-0 text-start px-0">{{ product.qty }}x {{ product.name }}<br>{{ product.sku }}</td>
                  <td class="border-0 text-end px-0">{{ product.base_row_total_incl_tax }} €</td>
                </tr>
              </tbody>
              <tfoot class="border-top border-black">
                <tr class=" fw-bold">
                  <td class="text-start px-0">Total TTC</td>
                  <td class="text-end px-0">{{ data.base_grand_total }} €</td>
                </tr>
              </tfoot>
            </table>
            <table class="table">
              <tbody>
                <tr>
                  <td class="border-0  text-start px-0">Total HT</td>
                  <td class="border-0  text-end px-0">{{ data.base_subtotal }} €</td>
                </tr>
                <tr>
                  <td class="border-0  col-auto text-start px-0">Total TVA</td>
                  <td class="border-0  col-auto text-end px-0">{{ data.base_tax_amount }} €</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <strong>Aidez-nous à faire toujours mieux !</strong><br>
            <br>
            Nous vous offrons <strong>10 €*</strong> en bon d'achat à valoir sur votre prochaine commande en laissant un avis sur Google.<br>
            <br>
            Découvrez encore plus de produits sur notre boutique en ligne :<br>
            <u>www.espaceplaisir.fr</u>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 font-ean">
            {{ order.entity_id }}
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            *conditions disponibles ici :<br>
            <u>espaceplaisir.fr/lier-commande-boutique</u>
          </div>
        </div>

      </div>
    </div>

    <div class="row bg-light border-top py-3">
        <div class="col-12">
          <div class="row">
            <div class="col d-flex justify-content-start me-auto">
              <button v-if="invoiceFormat == 'invoice'" class="btn btn-primary me-3" @click="toggleInvoiceFormat">Format ticket de caisse</button>
              <button v-else-if="invoiceFormat == 'receipt'" class="btn btn-primary me-3" @click="toggleInvoiceFormat">Format détaillé</button>
            </div>
            <div class="col d-flex justify-content-end">
              <button class="btn btn-success me-3" @click="printInvoice(invoiceFormat)">Imprimer</button>
              <button class="btn btn-success me-3">Envoyer par e-mail</button>
              <button class="btn btn-danger" @click="closeWorkplan">Fermer</button>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate } from '@/utils/tools';

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      invoiceFormat: 'invoice',
    };
  },
  computed: {
    ...mapGetters('order', ['order']),
    ...mapGetters('user', ['user']),
  },
  methods: {
    ...mapActions('address', ['fetchCountries']),
    formatDate,
    closeWorkplan() {
      this.$emit('close-workplan');
    },
    toggleInvoiceFormat() {
      this.invoiceFormat = this.invoiceFormat == 'invoice' ? 'receipt' : 'invoice';
    },
  },
  mounted() {
    this.fetchCountries();
  }
}
</script>
  