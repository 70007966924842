import backend from './backendService';

class AddressService {
  async getAddress(addressId) {
    return backend.get(`/address/${addressId}`);
  }

  async getCountries() {
    return backend.get('/config/countries');
  }

  async getRegions(countryId) {
    return backend.get(`/config/countries/${countryId}`);
  }
}

export default new AddressService();