<template>
  <div class="row d-flex align-content-center align-items-center justify-content-between border-bottom h-100">
    <div class="col-12 d-flex flex-column h-100">

      <!-- Action multiple -->
      <div class="row h-64 align-content-center align-items-center justify-content-between border-bottom">
        <div class="col-6">
          <div class="form-check text-start lh-base">
            <input class="form-check-input" role="button" type="checkbox" value="" id="select-all" @change="selectEveryProducts">
            <label class="form-check-label" role="button" for="select-all">Tout sélectionner</label>
          </div>    
        </div>

        <div class="col-6 text-end">
          <template v-if="selection.length > 0">
            <select class="form-select" @mouseup.stop.prevent="handleFormSelection">
              <option value="" disabled selected>Agir sur la sélection</option>
              <option value="ship">Générer une expédition</option>
              <option value="voucher">Générer un avoir</option>
              <option value="refund">Rembourser</option>
            </select>
          </template>

          <template v-else>
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addProductModal">Ajouter un produit</button>
            <add-product-modal :order="order" />
          </template>

        </div>
      </div>

      <!-- Liste des produits -->
      <div class="row flex-grow-1 overflow-y-auto" style="min-height: 0;">
        <draggable class="col-12" :list="order.items" :group="{ name: 'products', pull: 'clone', put: false}" :multi-drag="true" selected-class="selected" :sort="false" :itemKey="item => item.sku" @end="clearSelection">
          <template v-slot:item="{ element, index }">
            <article v-if="element" class="row order-product text-start border-bottom" :key="index" :sku="element.sku" :order_item_id="element.item_id"  style="user-select: text;" @click="toggleProductSelection(element)">
              <order-product :product="element" />
            </article>
          </template>
        </draggable>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Sortable from 'sortablejs';
import OrderProduct from '@/components/order/OrderProduct';
import AddProductModal from '@/components/modals/AddProductModal';

export default {
  components: {
    OrderProduct,
    AddProductModal,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selection: [],
    };
  },
  methods: {
    ...mapActions('order', ['createRefund']),
    ...mapActions('preparation', ['initPreparation']),
    toggleProductSelection(product) {
      const index = this.selection.findIndex(p => p.sku == product.sku);
      if (index == -1) this.selection.push(product);
      else this.selection.splice(index, 1);
    },
    selectEveryProducts(event) {
      const selected = event.target.checked;
      const draggable = document.querySelectorAll('.order-product');
      if (selected) {
        draggable.forEach(element => {
          this.selection = [...this.order.items];
          Sortable.utils.select(element);
        });
      } else {
        draggable.forEach(element => {
          this.selection = [];
          Sortable.utils.deselect(element);
        });
      }
    },
    async handleFormSelection(event) {
      event.stopPropagation();
      if (event.target.value == 'refund') {
        await this.createRefund({ items: this.selection });
      }
      else if (event.target.value == 'ship') {
        await this.initPreparation({ order: this.order, items: this.selection });
        this.$emit('load-workplan', 'OrderPreparation', 'open');
      }
      event.target.value = '';
    },
    clearSelection() {
      this.selection = [];
    }
  },
};
</script>
