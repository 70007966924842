<template>
  <div class="row h-128">

    <article class="col-xxl-3 col-3 h-100 ms-auto">
      <div class="card h-100">
        <div class="card-body d-flex flex-column justify-content-between">
          <div class="row mb-3">
            <div class="col">
              <div class="card-text fw-bold">Paiement par carte bancaire</div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <div class="input-group">
                <input type="number" v-model.number="paid" id="paid" class="form-control" ref="focus" autofocus>
                <span class="input-group-text">€</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </article>

    <article class="col-2 h-100">
      <div class="card h-100 bg-primary text-white text-decoration-none">
        <div class="card-body d-flex flex-column justify-content-around text-center" role="button" data-bs-target="#adyenPaymentModal" data-bs-toggle="modal">
          <div class="row">
            <div class="col">
              <i class="bi bi-bag-check fs-1"></i>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card-text">
                Ouvrir Adyen
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>

  </div>

  <adyen-payment-modal @payment-completed="paymentCompleted"/>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AdyenPaymentModal from '@/components/modals/AdyenPaymentModal.vue';

export default {
  props: {
    paymentMethod: {
      type: String,
      required: true
    }
  },
  components: {
    AdyenPaymentModal,
  },
  data() {
    return {
      paid: null,
    };
  },
  computed: {
    ...mapGetters('cart', ['cart']),
  },
  methods: {
    ...mapActions('alerts', ['setAlert']),
    paymentCompleted(adyen_data) {
      this.placeOrder(adyen_data)
    },
    placeOrder(adyen_data) {
      this.$emit('place-order', {
        paymentMethod: this.paymentMethod,
        adyen: adyen_data,
      });
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.paid.focus();
      });
    }
  },
  mounted() {
    this.focusInput();
    if (this.cart && this.cart.totals && this.cart.totals.grand_total !== undefined) {
      this.paid = this.cart.totals.grand_total;
    }
  }
};
</script>
