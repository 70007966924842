<template>
  <div class="row py-3 border-top">
    <div class="col">
      <div class="row h-32 align-items-center my-2">
        <div class="col fw-bold">Total produit</div>
        <div v-if="cart && cart.totals" class="col text-end fw-bold">{{ cart.totals.subtotal_incl_tax }} €</div>
      </div>
      <div class="row h-32 align-items-center my-2">
        <div class="col fw-bold">Bon de réduction</div>
        <div class="col text-end fw-bold"><button class="btn btn-primary btn-sm" data-bs-target="#addCouponModal" data-bs-toggle="modal">Ajouter</button></div>
      </div>
      <div v-if="cart && cart.totals && cart.totals.discount_amount" class="row h-32 align-items-center my-2">
        <div class="col fw-bold">Réduction</div>
        <div class="col text-end fw-bold">{{ cart.totals.discount_amount }} €</div>
      </div>
      <div class="row h-32 align-items-center my-2">
        <div class="col fw-bold">Livraison</div>
        <div v-if="cart && cart.totals && cart.totals.shipping_amount" class="col text-end fw-bold">{{ cart.totals.shipping_amount }} € </div>
        <div v-else class="col text-end fw-bold">Gratuite</div>
      </div>
    </div>
  </div>

  <add-coupon-modal :cart="cart" @add-coupon="addCoupon" />

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddCouponModal from '@/components/modals/AddCouponModal.vue';

export default {
  components: {
    AddCouponModal,
  },
  computed: {
    ...mapGetters('cart', ['cart']),
  },
  methods: {
    ...mapActions('cart', ['fetchCartTotals']),
    addCoupon(coupon_code) {
      this.$emit('add-coupon', coupon_code);
    }
  },
  async mounted() {
    if (!this.cart.totals) {
      await this.fetchCartTotals();
    }
  }
};
</script>
