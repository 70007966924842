<template>

  <div v-if="switchboard && switchboard.error" class="row h-100">
    <div class="row justify-content-center align-items-center">
      {{ error }}
    </div>
  </div>

  <div v-else-if="switchboard" class="row overflow-auto">
    <div class="col-12 overflow-auto">

      <div class="row overflow-auto p-5 border-bottom bg-light">
        <div class="col-12 text-center">
          <h1>Standard téléphonique</h1>
        </div>
      </div>

      <div class="row justify-content-center overflow-auto">
        <div class="col-12 overflow-auto px-5 pt-5">

          <table class="table table-hover align-middle">
            <thead>
              <tr>
                <th scope="col" @click="sortByColumn('id')">#</th>
                <th scope="col" @click="sortByColumn('source')">Source</th>
                <th scope="col" @click="sortByColumn('order_id')">Numéro de commande</th>
                <th scope="col" @click="sortByColumn('time')">Heure</th>
                <th scope="col" @click="sortByColumn('customer')">Client</th>
                <th scope="col" @click="sortByColumn('telephone')">Numéro de téléphone</th>
                <th scope="col" @click="sortByColumn('service')">Service</th>
                <th scope="col" @click="sortByColumn('date_add')">Date de l'appel</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr v-for="call in switchboard" :key="call.id">
                <td class="py-3">{{ call.id }}</td>
                <td class="py-3">
                  <img :src="sourceIcon(call.source)" alt="Logo" width="24" height="24" />
                </td>
                <td class="py-3">
                  <router-link :to="'/orders/' + call.order_id" class="text-black text-decoration-none" target="_blank">
                    {{ call.order_id }}
                  </router-link>
                </td>
                <td class="py-3">{{ formatDate(call.date_add, 'time') }}</td>
                <td class="py-3">{{ call.customer_id }}</td>
                <td class="py-3">{{ call.telephone }}</td>
                <td class="py-3">{{ call.name }}</td>
                <td class="py-3">{{ formatDate(call.date_add, 'date') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>

  <div v-else class="row h-100 overflow-auto">
    <loader />
  </div>
</template>
  
  
<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate, sourceIcon } from '@/utils/tools';

export default {
  components: {
  },
  props: {

  },  
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters('switchboard', ['switchboard']),
  },
  methods: {
    ...mapActions('switchboard', ['fetchCalls', 'clearSwitchboard']),
    formatDate, sourceIcon
  },
  mounted() {
    if (!this.switchboard) {
      this.fetchCalls();
    }
    setInterval(this.fetchCalls, 10000);
  },
  unmounted() {
    this.clearSwitchboard();
  }
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: block;
}</style>