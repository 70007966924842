import refundService from '@/services/refundService';

export default {
  namespaced: true,
  state: {
    loading: false,
    error: null,
    refund: {},
  },
  mutations: {
    SET_REFUND(state, refund) {
      state.refund = refund;
    },
    SET_REFUND_PRODUCTS(state, products) {
      state.refund = { ...state.refund, products };
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
    CLEAR_REFUND(state) {
      state.refund = {};
    },
  },
  actions: {
    async fetchRefundRequest({ commit }, id_refund) {
      try {
        commit('SET_LOADING', true);
        commit('SET_REFUND', await refundService.getRefundRequest(id_refund));
        commit('SET_REFUND_PRODUCTS', await refundService.getRefundRequestProducts(id_refund));
        commit('SET_LOADING', false);
      } catch (error) {
        console.error(error);
      }
    },
    async clearRefundRequest({ commit }) {
      commit('CLEAR_REFUND');
    },

    async initRefundRequest({ commit }, { order, items }) {
      try {

        const products = (items && Array.isArray(items)) ? items.map(product => ({
          qty: product.qty_invoiced || 1,
          id_refund_reason: 0,
          ...product, 
        })) : [];

        const request  = {
          store_id: order.store_id,
          order_id: order.entity_id,
          id_last_state: 0,
          grand_total: 0,
          payment_method: order.payment.method,
          products: products || [],
        };

        commit('SET_REFUND', request);
        commit('SET_REFUND_STATES', await refundService.getRefundStates());

      } catch (error) {
        console.error(error);
      }
    },
    async createRefundRequest({ dispatch, commit, rootState: { order: { order }}}, request) {
      try {
        commit('SET_LOADING', true);
    
        let products = [];
        let products_amount = 0;

        for (let product of request.products) {
          if (!product.id_refund_reason) {
            dispatch('alerts/setAlert', 
              { style: 'alert-warning', message: 'Choisissez un motif de remboursement pour chaque produit' }, 
              { root: true }
            ); 
            commit('SET_LOADING', false);
            return;
          } 
    
          products.push({
            id_refund_reason: product.id_refund_reason,
            order_item_id: product.order_item_id,
            sku: product.sku,
            product_amount: product.product_amount,
            qty: product.qty,
          });

          products_amount += product.product_amount;
        }
    
        const response = await refundService.createRefundRequest(order.entity_id, {
          store_id: request.store_id,
          order_id: request.order_id,
          grand_total: request.grand_total,
          products_amount: products_amount,
          id_refund_reason_shipping: request.id_refund_reason_shipping || 0,
          shipping_amount: request.shipping_amount || 0,
          id_refund_reason_fees: request.id_refund_reason_fees || 0,
          fees_amount: request.fees_amount || 0,
          id_refund_reason_gift: request.id_refund_reason_gift || 0,
          gift_amount: request.gift_amount || 0,
          payment_method: request.payment_method,
          products: products,
        });
    
        dispatch('order/fetchOrderRefunds', order.entity_id, { root: true });
    
        if (response && response.success) {
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }
        
        commit('CLEAR_REFUND');
        commit('SET_LOADING', false);
    
      } catch (error) {
        commit('SET_ERROR', error.message);
      }
    },
    async updateRefundRequest({ state, commit, dispatch, rootState: { order: { order }}}, request) {
      try {
        commit('SET_LOADING', true);
    
        let products = [];
        let products_amount = 0;

        for (let product of request.products) {
          if (!product.id_refund_reason) {
            dispatch('alerts/setAlert', 
              { style: 'alert-warning', message: 'Choisissez un motif de remboursement pour chaque produit' }, 
              { root: true }
            ); 
            commit('SET_LOADING', false);
            return;
          } 
    
          products.push({
            id_refund_product: product.id_refund_product || 0,
            id_refund_reason: product.id_refund_reason,
            order_item_id: product.order_item_id,
            sku: product.sku,
            product_amount: product.product_amount,
            qty: product.qty,
          });

          products_amount += product.product_amount;
        }
    
        const response = await refundService.updateRefundRequest(state.refund.id_refund, {
          grand_total: request.grand_total,
          products_amount: products_amount,
          id_refund_reason_shipping: request.id_refund_reason_shipping || 0,
          shipping_amount: request.shipping_amount || 0,
          id_refund_reason_fees: request.id_refund_reason_fees || 0,
          fees_amount: request.fees_amount || 0,
          id_refund_reason_gift: request.id_refund_reason_gift || 0,
          gift_amount: request.gift_amount || 0,
          products: products,
        });
    
        dispatch('order/fetchOrderRefunds', order.entity_id, { root: true });
    
        if (response && response.success) {
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }
        
        commit('CLEAR_REFUND');
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async addProduct({ state }, event) {
      const items = (event.items && event.items.length > 0) ? event.items : [event.item];
      items.forEach(() => {
          state.refund.products.forEach(product => {
            product.order_item_id = product.item_id,
            product.product_amount = product.base_price_incl_tax;
            product.qty = product.qty_invoiced;
            product.id_refund_reason = 0;
          });
      });
    },
    async updateProductRefundReason({ state }, { order_item_id, id_refund_reason}) {
      const product = state.refund.products.find(product => product.order_item_id == order_item_id);
      if (product) {
        product.id_refund_reason = id_refund_reason;
      }
    },
    async updateProductRefundQty({ state }, { order_item_id, qty}) {
      const product = state.refund.products.find(product => product.order_item_id == order_item_id);
      if (product) {
        product.qty = qty;
      }
    },
  }, 
  getters: {
    refund: state => state.refund,
    loading: state => state.loading,
    error: state => state.error,
  },
};
