<template>
  <div class="row align-items-center h-100 px-3">
    <div v-if="methods" class="row row-cols-2 gy-3 mt-0">
      <div v-for="(payment, index) in methods" :key="index" class="col">
        <button class="btn btn-primary btn-lg w-100 p-3" @click="selectPaymentMethod(payment.code)">
          <i :class="['fs-1', getPaymentIcon(payment.code)]"></i>
          <br>{{ payment.title }}
        </button>
      </div>
    </div>
  </div>

  <div v-if="selectedPaymentMethod" class="row border-top py-3 bg-light">
    <div class="col-12">
      <component :is="selectedPaymentComponent" :paymentMethod="selectedPaymentMethod" @place-order="placeOrder"/>
    </div>
  </div>
</template>

<script>
import CartPaymentCard from './CartPaymentCard.vue';
import CartPaymentCash from './CartPaymentCash.vue';
import CartPaymentAdyen from './CartPaymentAdyen.vue';
import CartPaymentAlma from './CartPaymentAlma.vue';

export default {
  props: {
    methods: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      selectedPaymentMethod: null,
    };
  },
  computed: {
    selectedPaymentComponent() {
      switch (this.selectedPaymentMethod) {
        case 'cb':
          return CartPaymentCard;
        case 'adyen_cc':
          return CartPaymentAdyen;
        case 'especes':
          return CartPaymentCash;
        case 'alma_pay_pos':
          return CartPaymentAlma;
        default:
          return null;
      }
    }
  },
  methods: {
    getPaymentIcon(paymentCode) {
      const array = {
        'especes': 'bi-cash' ,
        'cb': 'bi-credit-card',
        'alma_pay_pos': 'bi-123',
        'banktransfer': 'bi-bank' ,
        'checkmo': 'bi-credit-card',
      };
      return array[paymentCode] || array['default'];
    },
    selectPaymentMethod(paymentMethod) {
      this.selectedPaymentMethod = paymentMethod;
    },
    placeOrder(paymentMethod) {
      this.$emit('place-order', paymentMethod);
    },
  }
};
</script>
