import backend from './backendService';

class OrderService {
  async getOrder(order_id) {
    return backend.get(`/orders/${order_id}`);
  }
  
  async getOrderBadges(order_id) {
    return backend.get(`/orders/${order_id}/badges`);
  }

  async getOrderPreparations(order_id) {
    return backend.get(`/orders/${order_id}/preparations`);
  }

  async getOrderInvoices(order_id) {
    return backend.get(`/orders/${order_id}/invoices`);
  }

  async getOrderRefunds(order_id) {
    return backend.get(`/orders/${order_id}/refunds`);
  }

  async getOrderReturns(order_id) {
    return backend.get(`/orders/${order_id}/returns`);
  }

  async createRefund(order_id, items) {
    return backend.post(`/orders/${order_id}/refund`, items);
  }

  async createInvoice(order_id, notifyCapture) {
    return backend.post(`/orders/${order_id}/invoice`, notifyCapture);
  }

  async updateOrderStatus(order_id, status) {
    return backend.post(`/orders/${order_id}/status`, status);
  }

  async getOrderTabs() {
    return backend.get(`/config/orders/tabs`);
  }

  async getCarriers() {
    return backend.get(`/carriers`);
  }
}

export default new OrderService();
