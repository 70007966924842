<template>
  <div class="row justify-content-center mt-5">
    <div class="col-auto h5 fw-bold">Paiements</div>
  </div>
  <div v-if="order.payment" class="row justify-content-center">
    <div class="col">
      <table class="table border align-middle fs-sm">
        <tbody>
          <tr>
            <td class="col text-start text-break">
              <span v-for="(information, index) in order.payment.additional_information" :key="index">{{ information }}<br></span>
            </td>
            <td v-if="order.payment.cc_last4" class="col text-center">xxxx-xxxx-xxxx-{{ order.payment.cc_last4 }}</td>
            <td class="col-2 text-end fw-bold">{{ order.payment.amount_ordered }} €</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
};
</script>