<template>
  <div class="row navbar bg-dark">
    <ul class="nav align-items-center">
      <li class="nav-items"><router-link class="nav-link" to="/"><i class="nav-item fs-4 bi-house" title="Accueil"></i></router-link></li>
      <li v-for="tab in user.menu" :key="tab.name" class="nav-item">
        <router-link v-if="tab.navbar == 1" class="nav-link" :to="tab.link"><i class="nav-item fs-4" :class="tab.icon" :title="tab.label"></i></router-link>
      </li>

      <li class="nav-item dropdown ms-auto" v-if="user">
        <a class="nav-link dropdown-toggle text-white" href="#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" @click="loadUserStores">
          Bonjour {{ user.firstname }}
        </a>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
          <li><router-link class="dropdown-item" to="/users/me">Mon profil</router-link></li>
          <li><router-link class="dropdown-item" to="/users/settings">Paramètres</router-link></li>
          <li><hr class="dropdown-divider"></li>
          <li v-for="store in user.stores" :key="store.id" class="list-group-item">
            <a class="dropdown-item" href="#" @click="updateUserStore(store)">{{ store.name }}</a>
          </li>
          <li><hr class="dropdown-divider"></li>
          <li><a class="dropdown-item" href="#" @click="logout">Déconnexion</a></li>
        </ul>
      </li>

    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('user', ['user']),
  },
  methods: {
    ...mapActions('user', ['fetchUserStores', 'updateUserDefaultStore', 'logout']),
    loadUserStores() {
      if (!this.user.stores) {
        this.fetchUserStores();
      }
    },
    updateUserStore(store) {
      this.updateUserDefaultStore(store);
    }
  }
};
</script>

<style scoped>
.nav li:hover, 
.nav li.active {
  background: none;
}
.nav-link, 
.nav-link:focus {
  color: #fff;
}
.nav-link:hover {
  color: #ccc;
}
</style>
