<template>
  <div class="row justify-content-center mt-3 mb-3">
    <div class="col text-center">
      <img src="https://erp.belisoft.fr/backoffice/ErPstockv1/templates/img/icon-fraise-espaceplaisir.png" class="img-fluid" alt="logo espaceplaisir">
    </div>
  </div>
  <div class="row justify-content-center mb-3">
    <div class="col-auto h4 fw-bold w-50 bg-white border text-center p-3"># {{ order.increment_id }}</div>
  </div>
  <div class="row flex-column align-items-center mb-4">
    <div class="col-auto fs-5 fw-bold">
      {{ order.customer_firstname }} {{ order.customer_lastname }}
    </div>
    <div class="col-auto fs-sm">{{ order.customer_email }}</div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
};
</script>
