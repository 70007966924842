import addressService from '@/services/addressService';

export default {
  namespaced: true,
  state: {
    address: null,
    countries: [],
    regions: [],
  },
  mutations: {
    SET_ADDRESS(state, address) {
      state.address = address;
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    SET_REGIONS(state, regions) {
      state.regions = regions;
    },
  },
  actions: {
    async fetchAddress({ commit }, addressId) {
      try {
        const address = await addressService.getAddress(addressId);
        commit('SET_ADDRESS', address);
      } catch (error) {
        console.error('Failed to fetch address:', error.message);
      }
    },
    async fetchCountries({ commit }) {
      try {
        const countries = await addressService.getCountries();
        commit('SET_COUNTRIES', countries);
      } catch (error) {
        console.error('Failed to fetch countries:', error.message);
      }
    },
    async fetchRegions({ commit }, countryId) {
      try {
        const regions = await addressService.getRegions(countryId);
        commit('SET_REGIONS', regions);
      } catch (error) {
        console.error('Failed to fetch regions:', error.message);
      }
    },
  },
  getters: {
    address: state => state.address,
    countries: state => state.countries,
    regions: state => state.regions,
  }
};
