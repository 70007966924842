<template>
  <div class="row bg-light">
    <div class="col-12 px-5">
      
      <!-- Filtres -->
      <div class="row align-items-start bg-light py-3">
        <div class="col">
          <form @submit.prevent="searchCarts" class="row align-items-center" id="search">
            <div v-for="filter in activeFilters" :key="filter.field" class="col-lg-2 col-5 mb-2">
              <input v-if="filter.type == 'text'" v-model="filter.value" :placeholder="filter.label" class="form-control" />
              <input v-if="filter.type == 'number'" v-model="filter.value" :type="filter.type" :placeholder="filter.label" class="form-control" />
              <date-picker v-if="filter.type == 'date'" range valueType="YYYY-MM-DD" v-model:value="filter.value" separator=" - " format="DD/MM/YYYY" :shortcuts="shortcuts" :placeholder="filter.label" class="form-control border-0 p-0" />
              <multiselect v-if="filter.type == 'select'"
                v-model="filter.value"
                :options="filter.options"
                :multiple="true"
                :close-on-select="false"
                placeholder="Sélectionnez des boutiques"
                label="label"
                track-by="value">
                <template #selection="{ values, isOpen }">
                  <span class="multiselect__single" v-if="values.length > 1" v-show="!isOpen">{{ values.length }} boutiques selectionnées</span>
                  <span class="multiselect__single" v-else-if="values.length" v-show="!isOpen">{{ values.length }} boutique selectionnée</span>
                </template>
              </multiselect>
            </div>
          </form>
        </div>
        
        <div class="col-lg-auto col-1">
          <div class="row">
            <div class="col-lg-6 col-12 mb-3">
              <button type="submit" class="btn btn-primary" form="search">Rechercher</button>
            </div>
            <div class="col-lg-6 col-12 text-end">
              <a class="btn btn-secondary dropdown-toggle" href="#" role="button" @click="toggleFilterDropdown">
                Critères
              </a>
              <div v-if="showFilterDropdown" class="dropdown-menu show position-fixed end-0 mt-3 me-3 p-3">
                <div class="form-check" v-for="filter in filters" :key="filter.field">
                  <input class="form-check-input" type="checkbox" v-model="filter.active" />
                  <label class="form-check-label mt-1" :for="filter.field">{{ filter.label }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row overflow-y-auto">
    <div class="col-12 overflow-y-auto px-5 pt-5" style="max-height: 70vh;">
      <div v-if="search.loading" class="row h-100">
        <loader />
      </div>
      <div v-else-if="search.carts.length" class="col">
        <table class="table table-hover align-middle">
          <thead>
            <tr>
              <th scope="col" @click="sortByColumn('source')">Source</th>
              <th scope="col" @click="sortByColumn('id')">Numéro de panier</th>
              <th scope="col" @click="sortByColumn('customer')">Client</th>
              <th scope="col" @click="sortByColumn('created_at')">Heure</th>
              <th scope="col" @click="sortByColumn('grand_total')">Montant</th>
              <th scope="col" @click="sortByColumn('shipping_method')">Transporteur</th>
              <th scope="col" @click="sortByColumn('created_at')">Date de création</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr v-for="cart in sortedCarts" :key="cart.entity_id">
              <td class="py-3">
                <img :src="sourceIcon(cart.store_id)" alt="Logo" width="24" height="24" />
              </td>
              <td class="py-3">
                <router-link :to="'/carts/' + cart.id" class="text-black text-decoration-none">
                  {{ cart.id }}
                </router-link>
              </td>
              <td v-if="cart.customer_is_guest" class="py-3">-</td>
              <td v-else class="py-3">{{ cart.customer.firstname }} {{ cart.customer.lastname }}</td>

              <td class="py-3">{{ formatDate(cart.created_at, 'time') }}</td>
              <td class="py-3">{{ cart.grand_total }} 0 €</td>

              <td v-if="cart.extension_attributes && cart.extension_attributes.shipping_assignments.length > 0 && cart.extension_attributes.shipping_assignments[0].shipping.method" class="py-3">
                {{ cart.extension_attributes.shipping_assignments[0].shipping.method }}
              </td>
              <td v-else class="py-3">-</td>

              <td class="py-3">{{ formatDate(cart.created_at, 'date') }}</td>

            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="col text-center">
        <p>Aucun panier n'a été trouvé.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate, sourceIcon } from '@/utils/tools';
import DatePicker from 'vue-datepicker-next';
import Multiselect from 'vue-multiselect';
import 'vue-datepicker-next/index.css';
import 'vue-datepicker-next/locale/fr';
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  components: {
    DatePicker,
    Multiselect,
  },
  data() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); 
    const day = today.getDate().toString().padStart(2, '0');

    const start = `${year}-${month}-${day}`;
    const end = `${year}-${month}-${day}`;
    
    return {
      showFilterDropdown: false,
      filters: [
        { field: 'created_at', label: 'Plage de dates', type: 'date', active: true, value: [start, end], condition: 'range' },
        { field: 'store_id', label: 'Source', type: 'select', condition: 'in', active: true, value: [], options: [] },
        { field: 'id', label: 'Numéro de panier', type: 'text', active: true, value: '', condition: 'eq' },
        { field: 'customer_firstname', label: 'Prénom', type: 'text', active: true, value: '', condition: 'like' },
        { field: 'customer_lastname', label: 'Nom', type: 'text', active: true, value: '', condition: 'like' },
        { field: 'customer_email', label: 'Email', type: 'text', active: true, value: '', condition: 'like' },
        { field: 'grand_total_min', label: 'Montant total min', type: 'number', active: false, value: '', condition: 'gte' },
        { field: 'grand_total_max', label: 'Montant total max', type: 'number', active: false, value: '', condition: 'lte' },
        { field: 'status', label: 'Statut', type: 'select', active: false, value: '', condition: 'eq', options: [
          { value: 'pending', label: 'En attente' },
          { value: 'processing', label: 'En cours de traitement' },
        ] },
      ],
      sortBy: 'created_at', // Champ de tri par défaut
      sortDesc: true,       // Tri descendant par défaut
    };
  },
  computed: {
    ...mapGetters('search', ['search', 'shortcuts']),
    ...mapGetters('store', ['stores']),
    activeFilters() {
      return this.filters.filter(filter => filter.active);
    },
    sortedCarts() {
      let carts = [...this.search.carts];
      const compare = (a, b) => {
        const field = this.sortBy;
        if (a[field] < b[field]) return this.sortDesc ? 1 : -1;
        if (a[field] > b[field]) return this.sortDesc ? -1 : 1;
        return 0;
      };
      return carts.sort(compare);
    }
  },
  methods: {
    ...mapActions('search', ['fetchCarts']),
    ...mapActions('store', ['fetchStores']),
    formatDate, sourceIcon,
    toggleFilterDropdown() {
      this.showFilterDropdown = !this.showFilterDropdown;
    },
    async searchCarts() {
      const filters = this.filters.reduce((acc, filter) => {
        if (filter.active && filter.value && filter.value.length) {
          if (filter.type === 'select' && Array.isArray(filter.value)) {
            acc[filter.field] = { value: filter.value.map(option => option.value).join(','), condition: 'in' };
          } else {
            acc[filter.field] = { value: filter.value, condition: filter.condition };
          }
        }
        return acc;
      }, {});
      await this.fetchCarts({ filters });
    },
    sortByColumn(column) {
      if (this.sortBy == column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = column;
        this.sortDesc = false;
      }
    }
  },
  async mounted() {
    if (!this.search.carts.length) {
      this.searchCarts();
    }
    await this.fetchStores();
    const store = this.filters.find(filter => filter.field == 'store_id');
    const stores = this.stores.map(store => ({
      value: store.store_id,
      label: store.name,
    }));
    store.options = stores;
    store.value = stores;
  }
};
</script>
