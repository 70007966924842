import categoryService from '@/services/categoryService';
import searchService from '@/services/searchService';

export default {
  namespaced: true,
  state: {
    univers: [],
    categories: [],
    category: {
      children: null,
      products: [],
    },
    search: {
      results: [],
      error: null,
    },
  },
  mutations: {
    SET_UNIVERS(state, univers) {
      state.univers = univers;
    },
    ADD_CATEGORY_CHILDREN(state, children) {
      state.categories.push(...children);
    },
    SET_CATEGORY(state, category) {
      state.category = category;
    },
    SET_CATEGORY_PRODUCTS(state, products) {
      state.category.products = products;
    },
    SET_SEARCH_RESULTS(state, results) {
      state.search.results = results;
    },
    SET_SEARCH_ERROR(state, error) {
      state.search.error = error;
    },
    CLEAR_UNIVERS(state) {
      state.univers = [];
    },
    CLEAR_CATEGORIES(state) {
      state.categories = [];
    },
    CLEAR_CATEGORY(state) {
      state.category = {
        children: null,
        products: [],
      };
    },
    CLEAR_SEARCH(state) {
      state.search = {
        results: [],
        error: null,
      };
    },
  },
  actions: {
    async fetchUnivers({ commit }) {
      try {
        const univers = await categoryService.getCategories();
        commit('SET_UNIVERS', univers);
      } catch (error) {
        console.error('Failed to fetch universes:', error.message);
        throw error;
      }
    },
    async fetchCategory({ commit }, categoryId) {
      commit('CLEAR_SEARCH');
      commit('CLEAR_UNIVERS');
      commit('CLEAR_CATEGORY');
      commit('CLEAR_CATEGORIES');
      try {
        const [category, products] = await Promise.all([
          categoryService.getCategory(categoryId),
          categoryService.getCategoryProducts(categoryId),
        ]);
        commit('SET_CATEGORY', category);
        commit('SET_CATEGORY_PRODUCTS', products);

        if (category.children) {
          const categoryIds = category.children.split(',');
          const childrenPromises = categoryIds.map(id => categoryService.getCategory(id.trim()));
          const children = await Promise.all(childrenPromises);
          commit('ADD_CATEGORY_CHILDREN', children);
        }
      } catch (error) {
        console.error('Failed to fetch category:', error.message);
        throw error;
      }
    },
    async searchProducts({ commit }, { params, operation }) {
      if (params.name.value === '') {
        commit('CLEAR_SEARCH');
      } else {
        try {
          const results = await searchService.searchProducts(params, operation);
          if (results.length === 0) {
            commit('CLEAR_SEARCH');
          } else {
            commit('SET_SEARCH_RESULTS', results);
          }
        } catch (error) {
          console.error('Failed to search products:', error.message);
          commit('SET_SEARCH_ERROR', 'Erreur lors de la recherche de produits');
          throw error;
        }
      }
    },
    async clearSearch({ commit }) {
      commit('CLEAR_SEARCH');
    }
  },
  getters: {
    univers: state => state.univers,
    categories: state => state.categories,
    category: state => state.category,
    search: state => state.search,
  },
};
