import { createRouter, createWebHistory } from 'vue-router';

import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';

import User from '@/views/User.vue';
import UserProfile from '@/components/user/UserProfile.vue';
import UserCreate from '@/components/user/UserCreate.vue';

import Search from '@/views/Search.vue';
import SearchCarts from '@/components/search/SearchCarts.vue';
import SearchCustomers from '@/components/search/SearchCustomers.vue';
import SearchOrders from '@/components/search/SearchOrders.vue';
import SearchProducts from '@/components/search/SearchProducts.vue';
import SearchReturns from '@/components/search/SearchReturns.vue';
import SearchRefunds from '@/components/search/SearchRefunds.vue';

import Cart from '@/views/Cart.vue';
import Order from '@/views/Order.vue';
import Product from '@/views/Product.vue';

import Cashier from '@/views/Cashier.vue';
import Inventory from '@/views/Inventory.vue';
import Locations from '@/views/Locations.vue';
import Switchboard from '@/views/Switchboard.vue';

import store from '@/store';

const routes = [
  { path: '/', component: Home, meta: { requiresAuth: true } },
  { path: '/login', component: Login },

  { path: '/users', component: User, meta: { requiresAuth: true },
    children: [
      { path: 'me', component: UserProfile, name: 'UserProfile',  meta: { requiresAuth: true }},
      { path: 'create', component: UserCreate, name: 'UserCreate',  meta: { requiresAuth: true }},
      { path: '', redirect: { name: 'UserProfile'}}
    ]
  },

  { path: '/carts', component: Search, children: [{ path: '', component: SearchCarts, name: 'Carts', meta: { requiresAuth: true }}]},
  { path: '/customers', component: Search, children: [{ path: '', component: SearchCustomers, name: 'Customers', meta: { requiresAuth: true }}]},
  { path: '/orders', component: Search, children: [{ path: '', component: SearchOrders, name: 'Orders', meta: { requiresAuth: true }}]},
  { path: '/products', component: Search, children: [{ path: '', component: SearchProducts, name: 'Products', meta: { requiresAuth: true }}]},
  { path: '/returns', component: Search, children: [{ path: '', component: SearchReturns, name: 'Returns', meta: { requiresAuth: true }}]},
  { path: '/refunds', component: Search, children: [{ path: '', component: SearchRefunds, name: 'Refunds', meta: { requiresAuth: true }}]},

  { path: '/carts/:cart_id', component: Cart, name: 'Cart', props: (route) => ({ cart_id: route.params.cart_id, checkout: false }), meta: { requiresAuth: true }},
  { path: '/carts/:cart_id/checkout', component: Cart, name: 'Checkout', props: (route) => ({ cart_id: route.params.cart_id, checkout: true }), meta: { requiresAuth: true }},
  { path: '/orders/:order_id', component: Order, name: 'Order', props: (route) => ({ order_id: route.params.order_id }), meta: { requiresAuth: true }},
  { path: '/orders/:order_id/:activetab', component: Order, name: 'OrderTabs', props: (route) => ({ order_id: route.params.order_id, activetab: route.params.activetab }), meta: { requiresAuth: true }},
  { path: '/orders/:order_id/:activetab/:activeplan', component: Order, name: 'OrderWorkplan', props: (route) => ({ order_id: route.params.order_id, activetab: route.params.activetab, activeplan: route.params.activeplan }), meta: { requiresAuth: true }},
  { path: '/products/:product_sku', component: Product, name: 'Product', props: (route) => ({ product_sku: route.params.product_sku }), meta: { requiresAuth: true }},

  { path: '/cashier', component: Cashier, name: 'Cashier', meta: { requiresAuth: true }},
  { path: '/inventory', component: Inventory, name: 'Inventory', meta: { requiresAuth: true }},
  { path: '/locations', component: Locations, name: 'Locations', meta: { requiresAuth: true }},
  { path: '/switchboard', component: Switchboard, name: 'Switchboard', meta: { requiresAuth: true }},
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['user/isAuthenticated']) {
      next({
        path: '/login',
        query: { redirect: from.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
