import axios from 'axios';
import store from '@/store';

class Backend {
  constructor() {
    this.backend = axios.create({
      baseURL: 'https://oms.belisoft.io/backend/rest/v1',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    this.backend.interceptors.request.use(config => {
      const token = store.getters['user/token'];
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    }, error => {
      return Promise.reject(error);
    });

    this.backend.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response && error.response.status === 401) {
        store.dispatch('user/logout');
        window.location.href = '/login';
      }
      return Promise.reject(error);
    });
  }

  async request(method, endpoint, data = null, params = null, store_code = 'all') {
    try {
      const response = await this.backend.request({ method, url: `${store_code}${endpoint}`, data, params });
      if (response.data.error && response.data.error.message) {
        store.dispatch('alerts/setAlert', { style: 'alert-danger', message: response.data.error.message }, { root: true });
        return Promise.reject(new Error(response.data.error.message));
      } return response.data;
    } catch (error) {
      console.error(error);
      store.dispatch('alerts/setAlert', { style: 'alert-danger', message: error.response.data.message }, { root: true });
    }
  }

  async get(endpoint, params = null, store_code) {
    return this.request('get', endpoint, null, params, store_code);
  }

  async post(endpoint, data, store_code) {
    return this.request('post', endpoint, data, null, store_code);
  }

  async put(endpoint, data, store_code) {
    return this.request('put', endpoint, data, null, store_code);
  }

  async delete(endpoint, store_code) {
    return this.request('delete', endpoint, null, store_code);
  }
}

export default new Backend();
