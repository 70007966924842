import backend from './backendService';

class CategoryService {
  async getCategories() {
    return backend.get(`/categories`);
  }

  async getCategory(categoryId) {
    return backend.get(`/categories/${categoryId}`);
  }

  async getCategoryProducts(categoryId) {
    return backend.get(`/categories/${categoryId}/products`);
  }
}

export default new CategoryService();