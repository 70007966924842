<template>
  <div v-if="data" class="row align-items-center border">
    <div class="col-1 py-2">
      <img v-if="data && data.media_gallery_entries && data.media_gallery_entries.length > 0" class="img-fluid border" :src="productImageUrl(data.media_gallery_entries[0].file)" alt="Product Image">
      <img v-else class="img-fluid border" :src="productImageUrl('/placeholder/websites/1/img_placeholder_base.jpg')" alt="Product Image">
    </div>
    <div class="col d-flex align-items-center fw-bold lh-sm">
      {{ data.name }}
    </div>
    <div class="col d-flex align-items-center fw-bold lh-sm">
      <div class="row d-flex flex-column">
        <div v-if="product.new" class="col pt-2 fs-sm">
          <select class="form-select form-select-sm" id="reasonSelect" v-model="reason_id">
            <option value="0">Séléctionnez la raison du retour</option>
            <option v-for="reason in rma.states.reasons" :key="reason.reason_id" :value="reason.reason_id">
              {{ reason.name }}
            </option>
          </select>
        </div>
        <div v-else class="col fs-sm">
          Raison : {{ displayReason(reason_id).name }}
        </div>
        <div v-if="product.new" class="col py-2 fs-sm">
          <select class="form-select form-select-sm" id="conditionSelect" v-model="condition_id">
            <option value="0">Séléctionnez l'état du produit</option>

            <option v-for="condition in rma.states.conditions" :key="condition.condition_id" :value="condition.condition_id">
              {{ condition.name }}
            </option>
          </select>
        </div>
        <div v-else class="col fs-sm">
          Etat : {{ displayCondition(condition_id).name }}
        </div>
        <div v-if="product.new" class="col pb-2 fs-sm">
          <select class="form-select form-select-sm" id="resolutionSelect" v-model="resolution_id">
            <option value="0">Séléctionnez la résolution souhaitée</option>
            <option v-for="resolution in rma.states.resolutions" :key="resolution.resolution_id" :value="resolution.resolution_id">
              {{ resolution.name }}
            </option>
          </select>
        </div>
        <div v-else class="col fs-sm">
          Résolution : {{ displayResolution(resolution_id).name }}
        </div>
      </div>
    </div>
    <div v-if="!product.new" class="col d-flex flex-column lign-items-center fw-bold lh-sm">
      <label for="statusSelect" class="form-label fs-sm">Status du produit :</label>
      <select class="form-select form-select-sm" @change="updateStatus">
        <option value="0" selected>-</option>
        <option value="1">Accepté</option>
        <option value="2">Reçu</option>
        <option value="3">Terminé</option>
        <option value="4">Refusé</option>
      </select>
    </div>
    <div class="col-auto square d-flex flex-column justify-content-center fw-bold">
      <label class="form-label fs-sm">Qty :</label>
      <input type="text" :value="product.qty" class="form-control form-control-sm text-center" />
    </div>
  </div>
  <div v-else class="row h-64 align-items-center border">
    <div class="col-12">
      <loader/>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import { productImageUrl } from '@/utils/tools';
import productService from '@/services/productService';

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: null,
      reason_id: null,
      condition_id: null,
      resolution_id: null,
    };
  },
  watch: {
    reason_id(reason_id) {
      this.updateProductReason({ 
        request_item_id: this.product.request_item_id, 
        reason_id: reason_id 
      });
    },
    condition_id(condition_id) {
      this.updateProductCondition({ 
        request_item_id: this.product.request_item_id, 
        condition_id: condition_id 
      });
    },
    resolution_id(resolution_id) {
      this.updateProductResolution({ 
        request_item_id: this.product.request_item_id, 
        resolution_id: resolution_id 
      });
    },
    item_status(item_status) {
      this.updateProductStatus({ 
        request_item_id: this.product.request_item_id, 
        item_status: item_status 
      });
    },
  },
  computed: {
    ...mapGetters('rma', [
      'rma'
    ]),
  },
  methods: {
    ...mapActions('rma', [
      'updateProductReason',
      'updateProductCondition',
      'updateProductResolution',
      'updateProductStatus',
    ]),
    productImageUrl,
    displayReason(reason_id) {
      return this.rma && this.rma.states
        ? this.rma.states.reasons.find(reason => reason.reason_id == reason_id)
        : null;
    },
    displayCondition(condition_id) {
      return this.rma && this.rma.states
        ? this.rma.states.conditions.find(condition => condition.condition_id == condition_id)
        : null;
    },
    displayResolution(resolution_id) {
      return this.rma && this.rma.states
        ? this.rma.states.resolutions.find(resolution => resolution.resolution_id == resolution_id)
        : null;
    },
  },
  async mounted() {
    this.data = await productService.getProduct(this.product.sku);
    this.reason_id = this.product.reason_id;
    this.condition_id = this.product.condition_id;
    this.resolution_id = this.product.resolution_id;
  },
}
</script>
