<template>
  <div class="row h-128">

    <article class="col-xxl-3 col-3 h-100 ms-auto">
      <div class="card h-100">
        <div class="card-body d-flex flex-column justify-content-between">
          <div class="row mb-3">
            <div class="col">
              <div class="card-text fw-bold">Paiement en 3 fois avec Alma</div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <div class="input-group">
                <input type="number" v-model.number="paid" id="paid" class="form-control" ref="focus" autofocus>
                <span class="input-group-text">€</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </article>

    <article class="col-2 h-100">
      <div class="card h-100 bg-primary text-white text-decoration-none">
        <div class="card-body d-flex flex-column justify-content-around text-center" role="button">
          <div class="row">
            <div class="col">
              <i class="bi bi-bag-check fs-1"></i>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card-text">
                Ouvrir Alma
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>

    <article class="col-2 h-100">
      <div class="card h-100 bg-success text-white text-decoration-none">
        <div class="card-body d-flex flex-column justify-content-around text-center" role="button" @click="placeOrder">
          <div class="row">
            <div class="col">
              <i class="bi bi-bag-check fs-1"></i>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card-text">
                Valider le paiement
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    paymentMethod: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      paid: null,
    };
  },
  computed: {
    ...mapGetters('cart', ['cart']),
  },
  methods: {
    ...mapActions('alerts', ['setAlert']),
    placeOrder() {
      if(this.paid >= this.cart.totals.grand_total) {
        this.$emit('place-order', this.paymentMethod);
      } else {
        this.setAlert({ style: 'alert-danger', message: 'La somme encaissée est insuffisante pour couvrir le montant total.' });
      }
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.focus.focus();
      });
    }
  },
  mounted() {
    this.focusInput();
    if (this.cart && this.cart.totals && this.cart.totals.grand_total != undefined) {
      this.paid = this.cart.totals.grand_total;
    }
  }
};
</script>
