import backend from './backendService';

class AuthService {
  async login(email, password) {
    return backend.post('/users/login', { email, password });
  }

  async logout() {
    return;
  }

  async getProfiles() {
    return backend.get('/config/profiles');
  }

  async getRoles() {
    return backend.get('/config/roles');
  }
  
}

export default new AuthService();