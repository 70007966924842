import backend from './backendService';

class CustomerService {
  async getCustomers() {
    return backend.get('/customers');
  }

  async getCustomer(customerId) {
    return backend.get(`/customers/${customerId}`);
  }

  async createCustomer(customer) {
    return backend.post('/customers', customer);
  }
}

export default new CustomerService();
