import switchboardService from '@/services/switchboardService';

export default {
  namespaced: true,
  state: {
    switchboard: null,
  },
  mutations: {
    SET_CALLS(state, calls) {
      state.switchboard = calls;
    },
    SET_ERROR(state, error) {
      state.switchboard.error = error;
    },
    CLEAR_SWITCHBOARD(state) {
      state.switchboard = null;
    },
  },
  actions: {
    async fetchCalls({ commit }) {
      try {
        commit('SET_CALLS', await switchboardService.getCalls());
      } catch (error) {
        commit('SET_ERROR', error);
      }
    },
    async clearSwitchboard({ commit }) {
      commit('CLEAR_SWITCHBOARD');
    },
  },
  getters: {
    switchboard: state => state.switchboard,
  }
};
