<template>

  <div v-if="data" class="row align-items-center border">

    <div class="col-1">
      <img class="img-fluid" :src="productImageUrl(data.media_gallery_entries[0].file)" alt="Product Image">
    </div>

    <div class="col d-flex align-items-center fw-bold lh-sm">
      {{ data.name }}
    </div>

    <div class="col d-flex flex-column lign-items-center fw-bold lh-sm">
      <select v-model="id_refund_reason" class="form-select form-select-sm" required>
        <option value="0" disabled>Séléctionnez un motif de remboursement</option>
        <option v-for="(reason, index) in states.refunds.reasons.products" :key="index" :value="reason.id_refund_reason">
          {{ reason.name }}
        </option>
      </select>
    </div>

    <div class="col-auto square d-flex flex-column justify-content-center fw-bold">
      <input v-model="quantity" type="text" class="form-control form-control-sm text-center" />
    </div>

  </div>

  <div v-else class="row h-64 align-items-center border">
    <div class="col-12">
      <loader/>
    </div>
  </div>

</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import { productImageUrl } from '@/utils/tools';
import productService from '@/services/productService';

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: null,
      id_refund_reason: null,
      quantity: null,
    };
  },
  watch: {
    id_refund_reason(id_refund_reason) {
      this.updateProductRefundReason({ 
        order_item_id: this.product.order_item_id, 
        id_refund_reason: id_refund_reason 
      });
    },
    quantity(qty) {
      this.updateProductRefundQty({ 
        order_item_id: this.product.order_item_id, 
        qty: qty 
      });
    },
  },
  computed: {
    ...mapGetters('refund', ['refund']),
    ...mapGetters('states', ['states']),
  },
  methods: {
    ...mapActions('refund', [
      'updateProductRefundReason',
      'updateProductRefundQty',
    ]),
    productImageUrl,
  },
  async mounted() {
    this.data = await productService.getProduct(this.product.sku);
    this.id_refund_reason = this.product.id_refund_reason;
    this.quantity = this.product.qty;
  },
}
</script>
