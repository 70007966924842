<template>
  <div class="row bg-light">
    <div class="col-12 px-5">
      
      <div class="row align-items-start bg-light py-3">
        <div class="col">
          <form @submit.prevent="searchRefunds" class="row align-items-center" id="search">
            <div v-for="filter in activeFilters" :key="filter.field" class="col-lg-2 col-5 mb-2">
              <input v-if="filter.type == 'text'" v-model="filter.value" :placeholder="filter.label" class="form-control" />
              <input v-if="filter.type == 'number'" v-model="filter.value" :type="filter.type" :placeholder="filter.label" class="form-control" />
              <date-picker v-if="filter.type == 'date'" range valueType="YYYY-MM-DD" v-model:value="filter.value" separator=" - " format="DD/MM/YYYY" :shortcuts="shortcuts" :placeholder="filter.label" class="form-control border-0 p-0" />
              <multiselect v-if="filter.type == 'select'"
                v-model="filter.value"
                :options="filter.options"
                :multiple="true"
                :close-on-select="false"
                placeholder="Aucune option séléctionnée"
                label="label"
                track-by="value">
                <template #selection="{ values, isOpen }">
                  <span class="multiselect__single" v-if="values.length > 1" v-show="!isOpen">{{ values.length }} {{ filter.name }} selectionnées</span>
                  <span class="multiselect__single" v-else-if="values.length" v-show="!isOpen">{{ values.length }} {{ filter.name }} selectionnée</span>
                </template>
              </multiselect>
            </div>
          </form>
        </div>
        
        <div class="col-lg-auto col-1">
          <div class="row">
            <div class="col-lg-6 col-12 mb-3">
              <button type="submit" class="btn btn-primary" form="search">Rechercher</button>
            </div>
            <div class="col-lg-6 col-12 text-end">
              <a class="btn btn-secondary dropdown-toggle" href="#" role="button" @click="toggleFilterDropdown">
                Critères
              </a>
              <div v-if="showFilterDropdown" class="dropdown-menu show position-fixed end-0 mt-3 me-3 p-3">
                <div class="form-check" v-for="filter in filters" :key="filter.field">
                  <input class="form-check-input" type="checkbox" v-model="filter.active" />
                  <label class="form-check-label mt-1" :for="filter.field">{{ filter.label }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row overflow-y-auto">
    <div class="col-12 overflow-y-auto px-5 pt-5" style="max-height: 70vh;">
      <div v-if="search.loading" class="row h-100">
        <loader />
      </div>
      <div v-else-if="search.refunds.length" class="col">
        <table class="table table-hover align-middle">
          <thead>
            <tr>
              <th scope="col" @click="sortByColumn('source')">Source</th>
              <th scope="col" @click="sortByColumn('order_id')">Numéro de commande</th>
              <th scope="col" @click="sortByColumn('date_add')">Heure</th>
              <th scope="col" @click="sortByColumn('grand_total')">Montant total</th>
              <th scope="col" @click="sortByColumn('payment_method')">Mode de paiement</th>
              <th scope="col" @click="sortByColumn('status')">Statut</th>
              <th scope="col" @click="sortByColumn('products_amount')">Produits</th>
              <th scope="col" @click="sortByColumn('shipping_amount')">Frais de port</th>
              <th scope="col" @click="sortByColumn('fees_amount')">Pack Sérénité</th>
              <th scope="col" @click="sortByColumn('gift_amount')">Papier Cadeau</th>
              <th scope="col" @click="sortByColumn('date_add')">Date de création</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr v-for="(refund, index) in sortedRefunds" :key="index">
              <td class="py-3">
                <img :src="sourceIcon(refund.store_id)" alt="Logo" width="24" height="24" />
              </td>
              <td class="py-3">
                <router-link :to="'/orders/' + refund.order_id + '/refunds/' + refund.id_refund" class="text-black text-decoration-none">
                  {{ refund.order_id }}
                </router-link>
              </td>
              <td class="py-3">{{ formatDate(refund.date_add, 'time') }}</td>
              <td class="py-3">{{ refund.grand_total }} €</td>
              <td class="py-3">{{ refund.payment_method }}</td>
              <td class="py-3">
                <span v-if="displayState(refund.id_last_state)" :class="displayState(refund.id_last_state).color">
                  <i :class="`bi ${displayState(refund.id_last_state).icon} me-1 ${displayState(refund.id_last_state).color}`"></i>{{ displayState(refund.id_last_state).name }}
                </span>
              </td>
              <td class="py-3">{{ refund.products_amount }} €</td>
              <td class="py-3">{{ refund.shipping_amount }} €</td>
              <td class="py-3">{{ refund.fees_amount }} €</td>
              <td class="py-3">{{ refund.gift_amount }} €</td>
              <td class="py-3">{{ formatDate(refund.date_add, 'date') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="col text-center">
        <p>Aucune commande n'a été trouvée.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate, sourceIcon } from '@/utils/tools';
import DatePicker from 'vue-datepicker-next';
import Multiselect from 'vue-multiselect';
import 'vue-datepicker-next/index.css';
import 'vue-datepicker-next/locale/fr';
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  components: {
    DatePicker,
    Multiselect,
  },
  data() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); 
    const day = today.getDate().toString().padStart(2, '0');

    const start = `${year}-${month}-${day}`;
    const end = `${year}-${month}-${day}`;
    return {
      showFilterDropdown: false,
      filters: [
        { field: 'date_add', label: 'Plage de dates', type: 'date', active: true, value: [start, end], condition: 'range' },
        { field: 'store_id', label: 'Source', type: 'select', condition: 'in', active: true, value: [], options: [], name: 'boutiques' },
        { field: 'id_refund', label: 'Numéro de remboursement', type: 'text', active: true, value: '', condition: 'eq' },
        { field: 'order_id', label: 'Numéro de commande', type: 'text', active: true, value: '', condition: 'eq' },
        { field: 'grand_total_min', label: 'Montant total min', type: 'number', active: false, value: '', condition: 'gte' },
        { field: 'grand_total_max', label: 'Montant total max', type: 'number', active: false, value: '', condition: 'lte' },
        { field: 'id_last_state', label: 'Status de remboursement', type: 'select', condition: 'in', active: true, value: [], options: [], name: 'status' },
      ],
      sortBy: 'created_at', // Champ de tri par défaut
      sortDesc: true,       // Tri descendant par défaut
    };
  },
  computed: {
    ...mapGetters('search', ['search', 'shortcuts']),
    ...mapGetters('states', ['states']),
    ...mapGetters('store', ['stores']),
    activeFilters() {
      return this.filters.filter(filter => filter.active);
    },
    sortedRefunds() {
      let refunds = [...this.search.refunds];
      const compare = (a, b) => {
        const field = this.sortBy;
        if (a[field] < b[field]) return this.sortDesc ? 1 : -1;
        if (a[field] > b[field]) return this.sortDesc ? -1 : 1;
        return 0;
      };
      return refunds.sort(compare);
    }
  },
  methods: {
    ...mapActions('search', ['fetchRefunds']),
    ...mapActions('states', ['fetchRefundStates']),
    ...mapActions('store', ['fetchStores']),
    formatDate, sourceIcon,
    toggleFilterDropdown() {
      this.showFilterDropdown = !this.showFilterDropdown;
    },
    async searchRefunds() {
      const filters = this.filters.reduce((acc, filter) => {
        if (filter.active && filter.value && filter.value.length) {
          if (filter.type == 'select' && Array.isArray(filter.value)) {
            acc[filter.field] = { value: filter.value.map(option => option.value).join(','), condition: 'in' };
          } else {
            acc[filter.field] = { value: filter.value, condition: filter.condition };
          }
        }
        return acc;
      }, {});
      await this.fetchRefunds({ filters });
    },
    sortByColumn(column) {
      if (this.sortBy == column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortBy = column;
        this.sortDesc = false;
      }
    },
    displayState(id_last_state) {
      return this.states && this.states.refunds.states ? this.states.refunds.states.find(state => state.id_refund_state == id_last_state) : null;
    },
  },
  async mounted() {
    if (!this.search.orders.length) {
      this.searchRefunds();
    } 
    await this.fetchStores();
      const store = this.filters.find(filter => filter.field == 'store_id');
      const stores = this.stores.map(store => ({
        value: store.store_id,
        label: store.name,
      }));
      store.options = stores;
      store.value = stores;

    await this.fetchRefundStates();
      const status = this.filters.find(filter => filter.field == 'id_last_state');
      const states = this.states.refunds.states.map(status => ({
        value: status.id_refund_state,
        label: status.name,
      }));
      status.options = states;
      status.value = states;
    }
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: block;
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 ::-webkit-scrollbar-thumb {
  background: #888; 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}</style>