<template>
  <div class="col-12">
    <div v-if="component.error" class="row h-128">
      <div class="col-12">
        {{ component.error }}
      </div>
    </div>
    <div v-else-if="component.loading" class="row h-128">
      <loader />
    </div>

    <div v-else class="row h-128 flex-nowrap overflow-x-auto" @wheel="horizontalScroll">
      <action-button :icon="'bi-plus'" :text="'Nouvelle\nexpédition'" :text-color="'dark'" :type="'white'" @click="loadPreparation('create')" />

      <template v-for="(preparation, index) in order.preparations" :key="index">
        <article v-if="!preparation.store_id" class="col-3" :id="index">
          <div class="card h-100" role="button" @click.prevent="loadPreparation(preparation)">
            <div class="card-body d-flex flex-column justify-content-between">
              <div class="row">
                <div class="card-text fw-bold">Logistique</div>
              </div>
              <div class="row">
                <div class="card-text">{{ capitalizeEachWord(preparation.state_name) }}</div>
                <div class="card-text">{{ capitalizeEachWord(preparation.carrier_name) }}</div>
              </div>
              <div class="row">
                <div class="card-text">{{ formatDate(preparation.updated_at) }}</div>
              </div>
            </div>
          </div>
        </article>

        <article v-else class="col-3" :id="index" :key="index">
          <div class="card h-100" role="button" @click.prevent="loadPreparation(preparation)">
            <div class="card-body d-flex flex-column justify-content-between">
              <div class="row">
                <div class="card-text fw-bold">Boutique</div>
              </div>
              <div class="row">
                <div class="card-text">Achat en boutique à</div>
                <div class="card-text">{{ storeName(preparation.store_id) }}</div>
              </div>
              <div class="row">
                <div class="card-text">{{ formatDate(preparation.updated_at) }}</div>
              </div>
            </div>
          </div>
        </article>

      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { capitalizeEachWord, formatDate, storeName, horizontalScroll } from '@/utils/tools';
import ActionButton from '@/components/common/ActionButton';

export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      component: {
        loading: true,
        error: null
      },
    };
  },
  components: {
    ActionButton,
  },
  computed: {
    ...mapGetters('order', ['order']),
  },
  methods: {
    ...mapActions('order', ['fetchOrderPreparations']),
    capitalizeEachWord, formatDate, storeName, horizontalScroll,
    loadPreparation(element) {
      this.$emit('load-workplan', 'OrderPreparation', element);
    },
  },
  async mounted() {
    if (!this.order.preparations) {
      await this.fetchOrderPreparations();
    } this.component.loading = false;
  }
};
</script>
