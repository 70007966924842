<template>
  <div class="col border-bottom p-3">
    <div class="row align-items-center">
      <div class="col d-flex align-items-center fw-bold">{{ product.name }}</div>
      <div v-if="product.row_total_incl_tax" class="col-3 d-flex align-items-center justify-content-end" >
        {{ round(product.row_total_incl_tax, 2) }} €
      </div>
      <div v-else-if="product.price" class="col-3 d-flex align-items-center justify-content-end">
        {{ round(product.price, 2) }} €
      </div>
      <div class="col-auto d-flex align-items-center justify-content-space-between">
        <button type="button" class="btn btn-sm btn-light btn-outline-secondary me-1" @click="updateQuantity(-1)">
          <i class="bi bi-dash align-middle"></i>
        </button>
        <button type="button" class="btn btn-sm btn-light btn-outline-secondary" @click="updateQuantity(1)">
          <i class="bi bi-plus align-middle"></i>
        </button>
      </div>
    </div>
    <div v-if="product.qty > 1" class="row align-items-center mt-1">
      <div class="col d-flex align-items-center fs-sm">{{ product.base_price_incl_tax }} € x {{ product.qty }}</div>
    </div>
  </div>
</template>


<script>
import { round } from '@/utils/tools';
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      quantity: this.product.qty,
    }
  },
  methods: {
    round,
    async updateQuantity(value) {
      this.quantity += value
      if (this.quantity <= 0) {
        this.deleteProduct();
      } else {
        this.$emit('update-quantity', { item_id: this.product.item_id, qty: this.quantity });
      }
    },
    async deleteProduct() {
      if (!confirm('Êtes-vous sûr de vouloir supprimer ce produit ?')) {
        return;
      } else this.$emit('delete-product', this.product.item_id);
    },

  },
}
</script>