import backend from './backendService';

class CartService {

  async createCart(payload, store_code) {
    return backend.post('/carts', payload, store_code);
  }

  async getCart(cart_id) {
    return backend.get(`/carts/${cart_id}`);
  }
    
  async getCartCustomer(cart_id) {
    return backend.get(`/carts/${cart_id}/customer`);
  }
  
  async getCartProducts(cart_id) {
    return backend.get(`/carts/${cart_id}/products`);
  }

  async getCartTotals(cart_id) {
    return backend.get(`/carts/${cart_id}/totals`);
  }

  async getCartUser(cart_id) {
    return backend.get(`/carts/${cart_id}/user`);
  }

  async getCartBillingAddress(cart_id) {
    return backend.get(`/carts/${cart_id}/billing-address`);
  }

  async getCartShippingAddress(cart_id) {
    return backend.get(`/carts/${cart_id}/shipping-address`);
  }
  
  async getCartShippingMethods(cart_id) {
    return backend.get(`/carts/${cart_id}/shipping-methods`);
  }

  async getCartShippingMethod(cart_id) {
    return backend.get(`/carts/${cart_id}/shipping-method`);
  }

  async getCartPaymentMethods(cart_id) {
    return backend.get(`/carts/${cart_id}/payment-methods`);
  }

  async updateCartUser(cart_id, id_user) {
    return backend.put(`/carts/${cart_id}/user`, id_user);
  }

  async addCustomer(cart_id, customer) {
    return backend.put(`/carts/${cart_id}/customer`, customer);
  }

  async addCoupon(cart_id, couponCode) {
    return backend.post(`/carts/${cart_id}/coupons/${couponCode}`);
  }

  async addProduct(cart_id, cartItem) {
    return backend.post(`/carts/${cart_id}/items`, cartItem);
  }

  async updateQuantity(cart_id, itemId, cartItem) {
    return backend.put(`/carts/${cart_id}/items/${itemId}`, cartItem);
  }

  async deleteProduct(cart_id, itemId) {
    return backend.delete(`/carts/${cart_id}/items/${itemId}`);
  }

  async updateBillingAddress(cart_id, billingAddress) {
    return backend.post(`/carts/${cart_id}/billing-address`, billingAddress);
  }

  async setShippingInformation(cart_id, shippingInformation) {
    return backend.post(`/carts/${cart_id}/shipping-information`, shippingInformation);
  }

  async getPaymentMethods(cart_id) {
    return backend.get(`/carts/${cart_id}/payment-methods`);
  }

  async avaibleAdyenPaymentMethods(cart_id, cart_total) {
    return backend.post(`/carts/${cart_id}/adyen-methods`, cart_total);
  }

  async createAdyenPayment(cart_id, cart_total) {
    return backend.post(`/carts/${cart_id}/adyen-payment`, cart_total);
  }

  async placeOrder(cart_id, paymentMethod) {
    return backend.put(`/carts/${cart_id}/order`, paymentMethod);
  }

  async createInvoice(order_id, notifyCapture) {
    return backend.post(`/orders/${order_id}/invoice`, notifyCapture);
  }

}

export default new CartService();
