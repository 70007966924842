<template>
  <div class="col-12">
    
    <!-- Erreur de chargement -->
    <div v-if="page.error" class="row h-128">
      <div class="col-12">
        {{ page.error }}
      </div>
    </div>

    <!-- Chargement -->
    <div v-else-if="page.loading" class="row h-128">
      <loader />
    </div>

    <!-- Contenu -->
    <div v-else class="row h-128">
      
      <article class="col-2">
        <div class="card align-items-center py-4 h-100">
          <div class="card-body">
            <i class="add bi bi-plus-lg fs-1"></i>
          </div>
        </div>
      </article>

      <template v-for="(element, index) in addresses" :key="index">
        <article class="col-3" :id="index">
          <div class="card h-100" role="button" @click.prevent="loadAddress(element)">
            <div class="card-body d-flex flex-column justify-content-between">
              <div class="row">
                <div class="col">
                  <div class="card-text fw-bold">Adresse</div>
                </div>
                <div class="col-auto justify-item-end" role="button">
                  <i class="bi bi-pencil-square" title="Modifier l'adresse" data-bs-target="#editAddressModal" data-bs-toggle="modal"></i>
                </div>
              </div>
              <div class="row">
                <div class="card-text">{{ element.firstname }} {{ element.lastname }}</div>
                <div v-for="(street, streetIndex) in element.street" :key="streetIndex" class="card-text">{{ street }}</div>
                <div class="card-text">{{ element.postcode }} {{ element.city }}</div>
                <div class="card-text">{{ element.telephone }}</div>
              </div>
            </div>
          </div>
        </article>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      page: {
        loading: true,
        error: null
      },
      addresses: []
    };
  },
  async mounted() {
    try {
      if (this.order.billing_address) {
        this.addresses.push(this.order.billing_address);
      }
      if (this.order.extension_attributes && this.order.extension_attributes.shipping_assignments) {
        const shipping_assignments = this.order.extension_attributes.shipping_assignments;
        if (shipping_assignments.length > 0) {
          const shipping_address = shipping_assignments[shipping_assignments.length - 1].shipping.address;
          this.addresses.push(shipping_address);
        }
      }
    } catch (error) {
      this.page.error = error.message;
    } finally {
      this.page.loading = false;
    }
  },
  methods: {
    loadAddress(element) {
      this.$emit('load-workplan', 'OrderAddress', element);
    }
  }
};
</script>
