import authService from '@/services/authService';

export default {
  namespaced: true,
  state: {
    profiles: [],
    roles: [],
  },
  mutations: {
    SET_PROFILES(state, profiles) {
      state.profiles = profiles;
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
  },
  actions: {
    async fetchProfiles({ commit }) {
      try {
        commit('SET_PROFILES', await authService.getProfiles());
      } catch (error) {
        console.error('Erreur dans fetchProfiles:', error);
        throw error;
      }
    },
    async fetchRoles({ commit }) {
      try {
        commit('SET_ROLES', await authService.getRoles());
      } catch (error) {
        console.error('Erreur dans fetchRoles:', error);
        throw error;
      }
    },
  },
  getters: {
    profiles: state => state.profiles,
    roles: state => state.roles,
  }
};
