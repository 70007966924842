<template>

  <div class="row h-100 overflow-auto">
    <div class="col-12 h-100 overflow-auto">

      <div class="row h-100 overflow-auto">
        <div class="col-lg-3 col-12 h-100 d-flex flex-column border-end">

          <div class="row h-64 d-flex justify-content-between align-items-center border-bottom">
            <div class="col"><router-link :to="'/carts/' + cart.id" class="nav-link"><i class="fs-2 bi bi-arrow-left-circle text-black" title="Revenir au panier"></i></router-link></div>
            <div class="col-auto text-center">Valider le panier</div>
            <div class="col text-end"><a class="nav-link" href="#"><i class="fs-2 bi bi-clock text-warning" title="Mettre le panier en attente"></i></a></div>
          </div>

          <cart-customer />

          <div class="row h-100 justify-content-start">
            <div class="col-12 d-flex flex-column h-100">

              <cart-user  />

              <div class="row py-3 mb-2 border-bottom bg-light" @click="toggleStoreUsers">
                <div class="col">
                  <strong>Livraison</strong>
                </div>
              </div>

              <cart-address :address="cart.billing_address" type="billing" @update-address="updateAddress" />
              <cart-address :address="cart.shipping_address" type="shipping" @update-address="updateAddress" />
              <cart-shipping @update-shipping-method="onUpdateShippingMethod" />

            </div>
          </div>
          
          <cart-totals @add-coupon="onAddCoupon" />
        </div>

        <div class="col d-flex flex-column h-100">
          <div class="row h-128 align-items-center border-bottom">
            <div v-if="cart.totals" class="col text-center fs-1 fw-bold">Total : {{ cart.totals.grand_total }} €</div>
          </div>

          <cart-payment :methods="cart.payment_methods" @place-order="onPlaceOrder" />

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CartCustomer from '@/components/cart/CartCustomer';
import CartAddress from '@/components/checkout/CartAddress';
import CartShipping from '@/components/checkout/CartShipping';
import CartPayment from '@/components/checkout/CartPayment';
import CartTotals from '@/components/checkout/CartTotals';
import CartUser from '@/components/checkout/CartUser';

export default {
  components: {
    CartCustomer,
    CartAddress,
    CartShipping,
    CartPayment,
    CartTotals,
    CartUser,
  },
  computed: {
    ...mapGetters('cart', [
      'cart'
    ]),
  },
  methods: {
    ...mapActions('cart', [
      'fetchCart', 
      'fetchShippingMethods', 
      'fetchPaymentMethods', 
      'addCoupon', 
      'updateAddress', 
      'updateShippingMethod', 
      'placeOrder'
    ]),
    ...mapActions('order', [
      'createInvoice'
    ]),
    onAddCoupon(couponCode) {
      this.addCoupon(couponCode);
    },
    onUpdateShippingMethod(shipping_method) {
      this.updateShippingMethod({ shipping_method });
    },
    async onPlaceOrder(paymentMethod) {
      this.$router.push({ name: 'Order', params: { 
        order_id : await this.placeOrder(paymentMethod) 
      } });
    },
  },
  mounted() {
    this.fetchPaymentMethods();
  }
};
</script>