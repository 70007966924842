<template>
  <div class="row h-100 overflow-auto">
    <div class="col-12 h-100 overflow-auto">

      <div v-if="order && order.error" class="row h-100">
        <div class="row justify-content-center align-items-center">
          {{ error }}
        </div>
      </div>

      <div v-else-if="order" class="row h-100 overflow-auto">
        <div class="col-xxl-3 col-12 bg-light h-100 overflow-auto border-end">
          <order-summary :order="order" />
          <order-status />
          <order-billing :order="order" />
          <order-payment :order="order" />
          <customer-info :customer="customer" />
        </div>

        <div class="col-xxl-3 col-4 d-flex flex-column h-100 overflow-auto border-end">
          <order-products :order="order" />
        </div>

        <div class="col-xxl-6 col-8 d-flex flex-column justify-content-flex-start h-100 overflow-auto ">
          <order-tabs :order="order" :customer="customer" />
        </div>
      </div>

      <!-- Modals -->
      <edit-address-modal :address="address" @update-address="updateAddress" />
      <edit-carrier-modal v-if="order && order.carriers" :carriers="order.carriers" @update-shipping-method="updateShippingMethod" />

      <div v-else class="row h-100 overflow-auto">
        <loader />
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OrderSummary from '@/components/order/OrderSummary';
import OrderStatus from '@/components/order/OrderStatus';
import OrderBilling from '@/components/order/OrderBilling';
import OrderPayment from '@/components/order/OrderPayment';
import CustomerInfo from '@/components/order/CustomerInfo';
import OrderProducts from '@/components/order/OrderProducts';
import OrderTabs from '@/components/order/OrderTabs';
import EditAddressModal from '@/components/modals/EditAddressModal';
import EditCarrierModal from '@/components/modals/EditCarrierModal';

export default {
  components: {
    OrderSummary,
    OrderStatus,
    OrderBilling,
    OrderPayment,
    CustomerInfo,
    OrderProducts,
    OrderTabs,
    EditAddressModal,
    EditCarrierModal,
  },
  props: {
    order_id: {
      type: Number,
      required: true,
    },
    activetab: {
      type: String,
      required: false,
    },
    activeplan: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters('customer', ['customer']),
    ...mapGetters('order', ['order']),
    ...mapGetters('preparation', ['preparation']),
  },
  watch: {
    order_id: 'fetchOrderAndCustomer'
  },
  methods: {
    ...mapActions('customer', [
      'fetchCustomer',
    ]),
    ...mapActions('order', [
      'fetchOrder',
      'fetchCarriers', 
      'fetchOrderTabs', 
      'fetchOrderBadges', 
      'clearOrder', 
      'setActiveTab', 
      'setWorkplan',
    ]),
    ...mapActions('preparation', [
      'initPreparation',
    ]),
    async fetchOrderAndCustomer() {
      await this.fetchOrder(this.order_id);
      if (this.order && this.order.customer_id) {
        await this.fetchCustomer(this.order.customer_id);
      }
    },
  },
  async mounted() {

    await this.fetchOrderAndCustomer();
    await this.fetchOrderTabs();
    
    this.fetchOrderBadges();
    this.fetchCarriers();

    if (this.activetab) {
      this.setActiveTab(this.order.tabs.find(tab => tab.code == this.activetab));

      if (this.activeplan) {
        this.setWorkplan({ component: this.order.tabs.find(tab => tab.code == this.activetab).workplan, element: this.activeplan });
      }

    } else if (this.order.status == 'processing' && !this.order.preparations) {
      this.setWorkplan({ component: 'OrderPreparation', element: 'open' });
      this.initPreparation({ order: this.order, items: this.order.items });
    }
  },
  unmounted() {
    this.clearOrder();
  }
};
</script>
