<template>
  <div class="row h-64 d-flex justify-content-between align-items-center border-bottom">
    <div class="col">Articles ({{ cart.items_qty ? cart.items_qty : '0' }})</div>
    <div class="col-auto d-flex justify-content-end">
      <a class="nav-link" href="#"><i class="fs-2 bi bi-clock text-warning" title="Mettre le panier en attente"></i></a>
    </div>
    <div class="col-auto d-flex justify-content-end">
      <a class="nav-link" href="#"><i class="fs-2 bi bi-x-circle text-danger" title="Annuler le panier"></i></a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
    ...mapGetters('cart', ['cart']),
  },
};
</script>