<template>
  <div class="row py-2">
    <div class="col">
      <strong>Adresse de {{ stringType }}</strong>
    </div>
    <div class="col-auto justify-item-end" role="button">
      <i class="bi bi-pencil-square" title="Modifier l'adresse'" :data-bs-target="'#editAddressModal-' + type" data-bs-toggle="modal"></i>
    </div>
  </div>
  <div v-if="address" class="row row-cols-1 pb-3">
    <div class="col" v-for="(street, index) in address.street" :key="index">
      {{ street }}<br>
    </div>
    <div class="col">
      {{ address.postcode }} {{ address.city }}, {{ address.country_id }}
    </div>
  </div>
  <edit-address-modal :address="address" :type="type" @update-address="updateAddress" />
</template>

<script>
import EditAddressModal from '@/components/modals/EditAddressModal';

export default {
  components: {
    EditAddressModal,
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  computed: {
    stringType() {
      return this.type == 'billing' ? 'facturation' : 'livraison';
    }
  },
  methods: {
    updateAddress(type, address) {
      this.$emit('update-address', type, address);
    }
  }
};
</script>
