import backend from '@/services/backendService';

class SearchService {
  createSearchParams(filters, operation, limit, fields) {
    const filterGroups = [];

    Object.entries(filters).forEach(([field, filter]) => {
      const { value, condition } = filter;
      if (value [0] && value[1] && Array.isArray(value) && condition == 'range') {
        value[0] += ' 00:00:00';
        value[1] += ' 23:59:59';

        filterGroups.push({
          filters: [
            {
              field: `${field}`,
              value: value[0],
              condition_type: 'gteq'
            }
          ]
        });
        filterGroups.push({
          filters: [
            {
              field: `${field}`,
              value: value[1],
              condition_type: 'lteq'
            }
          ]
        });

      } else if (value && !Array.isArray(value) && operation == 'or' && field != 'type_id') {
        if (!filterGroups.length) {
          filterGroups.push({ filters: [] });
        }
        filterGroups[0].filters.push({
          field: field,
          value: condition == 'like' ? `%${value}%` : value,
          condition_type: condition
        });

      } else if (value && !Array.isArray(value)) {
        filterGroups.push({
          filters: [
            {
              field: field,
              value: condition == 'like' ? `%${value}%` : value,
              condition_type: condition
            }
          ]
        });
      }
    });

    const params = {
      'searchCriteria[filter_groups]': filterGroups,
      'searchCriteria[pageSize]': limit ?? 1000,
    };

    if (fields && fields.length > 0) {
      params.fields = `items[${fields.join(',')}]`;
    }

    return params;
  }

  async fetchResults(endpoint, params) {
    try {
      const response = await backend.get(endpoint, params);
      return response.items;
    } catch (error) {
      console.error(`Erreur lors de la recherche à l'endpoint ${endpoint} :`, error.message);
      throw error;
    }
  }

  async searchProducts(filters = {}, operation = null, limit = null, fields = []) {
    const params = this.createSearchParams(filters, operation, limit, fields);
    return this.fetchResults('/search/products', params);
  }

  async searchCustomers(filters = {}, operation = null) {
    const params = this.createSearchParams(filters, operation);
    return this.fetchResults('/search/customers', params);
  }

  async searchCarts(filters = {}) {
    const params = this.createSearchParams(filters);
    return this.fetchResults('/search/carts', params);
  }

  async searchOrders(filters = {}) {
    const params = this.createSearchParams(filters);
    return this.fetchResults('/search/orders', params);
  }

  async searchSalesRules(filters = {}) {
    const params = this.createSearchParams(filters);
    return this.fetchResults('/search/salesrules', params);
  }

  async searchCoupons(filters = {}) {
    const params = this.createSearchParams(filters);
    return this.fetchResults('/search/coupons', params);
  }

  async searchReturns(filters = {}) {
    const params = this.createSearchParams(filters);
    return this.fetchResults('/search/returns', params);
  }

  async searchRefunds(filters = {}) {
    const params = this.createSearchParams(filters);
    return this.fetchResults('/search/refunds', params);
  }
}

export default new SearchService();
