<template>
  <div class="col d-flex flex-column h-100">
    <div class="row flex-grow-1 justify-content-center overflow-auto">
      
      <template v-if="error">{{ error }}</template>
      <template v-else-if="loading"><loader/></template>
      <template v-else>
        
        <draggable
          class="row row-cols-3 flex-grow-1 overflow-y-auto justify-content-center align-items-center align-content-center"
          :list="refund.products"
          :group="{ name: 'refund-products', pull: true, put: ['products'] }"
          :multi-drag="false"
          selected-class="selected"
          :sort="false"
          @add="addProduct" >

          <template v-slot:item="{ element, index }">
            <article v-if="element.sku" class="col-10 m-1" :key="index" :sku="element.sku">
              <refund-product :product="element" />
            </article>
          </template>

        </draggable>
      </template>
    </div>

    <div class="row align-items-center border-top py-1 ">
      <div class="col-auto">
        <span v-if="refund.id_refund"><strong>Demande de remboursement #{{ refund.id_refund }}</strong> créé le {{ formatDate(refund.date_add) }}</span>
        <span v-else ><strong>Nouvelle demande de remboursement</strong></span>
      </div>
      <div class="col-auto ms-auto">
        <button v-if="refund.id_refund" class="btn btn-sm btn-danger" @click="closeWorkplan">Fermer</button>
        <button v-else class="btn btn-sm btn-danger" @click="closeWorkplan">Annuler</button>
      </div>
    </div>

    <div class="row border-top py-3 bg-light">
      <div class="col-12">
        <div class="row h-128 flex-nowrap overflow-x-auto" @wheel="horizontalScroll">
          
          <action-card 
            v-if="refund.shipping_amount || displayRefundShipping" 
            :title="'Frais de port'" 
            :modal="'editTrackingModal'">

            <template #content>
              <div class="input-group input-group-sm mb-2">
                <input v-model="refund.shipping_amount" type="number" class="form-control form-control-sm">
                <span class="input-group-text">€</span>
              </div>
              <select v-model="refund.id_refund_reason_shipping" class="form-select form-select-sm" aria-label="Default select example">
                <option value="0" selected disabled>Choisir une raison</option>
                <option v-for="(reason, index) in states.refunds.reasons.shipping" :key="index" :value="reason.id_refund_reason">{{ reason.name }}</option>
                <option value="0">Ne pas rembourser</option>
              </select>
            </template>
          </action-card>

          <action-card v-else :title="'Frais de port'" :col="'col-2'">
            <template #content>
              <button class="btn btn-sm btn-primary" @click="displayRefund('shipping')">Rembourser</button>
            </template>
          </action-card>
          
          <action-card 
            v-if="refund.fees_amount || displayRefundFees" 
            :title="'Pack Sérénité'">

            <template #content>
              <div class="input-group input-group-sm mb-2">
                <input v-model="refund.id_refund_reason_fees" type="number" class="form-control form-control-sm" >
                <span class="input-group-text">€</span>
              </div>
              <select class="form-select form-select-sm" aria-label="Default select example">
                <option value="0" selected disabled>Choisir une raison</option>
                <option v-for="(reason, index) in states.refunds.reasons.fees" :key="index" :value="reason.id_refund_reason">{{ reason.name }}</option>
                <option value="0">Ne pas rembourser</option>
              </select>
            </template>
          </action-card>

          <action-card v-else :title="'Pack Sérénité'" :col="'col-2'">
            <template #content >
              <button class="btn btn-sm btn-primary" @click="displayRefund('fees')">Rembourser</button>
            </template>
          </action-card>

          <action-card 
            v-if="refund.gift_amount || displayRefundGift" 
            :title="'Papier cadeau'">

            <template #content>
              <div class="input-group input-group-sm mb-2">
                <input v-model="refund.gift_amount" type="number" class="form-control form-control-sm">
                <span class="input-group-text">€</span>
              </div>
              <select v-model="refund.id_refund_reason_gift" class="form-select form-select-sm" aria-label="Default select example">
                <option value="0" selected disabled>Choisir une raison</option>
                <option v-for="(reason, index) in states.refunds.reasons.gift" :key="index" :value="reason.id_refund_reason">{{ reason.name }}</option>
                <option value="0">Ne pas rembourser</option>
              </select>
            </template>
          </action-card>

          <action-card v-else :title="'Emballage'" :col="'col-2'">
            <template #content >
              <button class="btn btn-sm btn-primary" @click="displayRefund('gift')">Rembourser</button>
            </template>
          </action-card>

          <action-card :title="'Montant calculé'" class="me-auto">
            <template #content>
              <div class="input-group input-group-sm mb-2">
                <input type="number" class="form-control form-control-sm" :value="grandTotal" readonly disabled>
                <span class="input-group-text">€</span>
              </div>
              <button class="btn btn-primary btn-sm" disabled>Modifier le montant</button>
            </template>
          </action-card>

          <action-button v-if="displayCreateRefundRequestButton" :icon="'bi-cloud-upload'" :text="'Créer une\ndemande'" :color="'white'" :type="'primary'" @click="createRefundRequest(refund)" />
          <action-button v-if="displayUpdateRefundRequestButton" :icon="'bi-cloud-upload'" :text="'Mettre\nà jour'" :color="'white'" :type="'primary'" @click="updateRefundRequest(refund)" />
          <action-button v-if="displayCreateRefundButton" :icon="'bi-check'" :text="'Valider le\nrembours.'" :color="'white'" :type="'success'" @click="createRefund(refund)" />

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { capitalizeEachWord, formatDate, horizontalScroll } from '@/utils/tools';
import RefundProduct from '@/components/order/OrderRefundProduct';
import ActionCard from '@/components/common/ActionCard';
import ActionButton from '@/components/common/ActionButton';

export default {
  components: {
    RefundProduct,
    ActionCard,
    ActionButton,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      selection: [],
      displayRefundShipping: false,
      displayRefundFees: false,
      displayRefundGift: false,
      id_refund_reason_shipping: null,
      id_refund_reason_fees: null,
      id_refund_reason_gift: null,
    };
  },
  watch: {
    data: {
      handler(refund) {
        if (refund == 'create') {
          this.initRefundRequest({ order: this.order });
        } else if (refund.id_refund) { 
          this.fetchRefundRequest(refund.id_refund);
        } else {
          this.fetchRefundRequest(refund);
        }
      },
      immediate: true,
      deep: true
    },
    grandTotal(value) {
      this.refund.grand_total = value;
    },
    id_refund_reason_shipping(id_refund_reason_shipping) {
      this.id_refund_reason_shipping = id_refund_reason_shipping;
      this.updateRefundReason({ 
        id_refund_reason_shipping: id_refund_reason_shipping 
      });
    },
    id_refund_reason_fees(id_refund_reason_fees) {
      this.id_refund_reason_fees = id_refund_reason_fees;
      this.updateProductRefundReason({ 
        id_refund_reason_fees: id_refund_reason_fees 
      });
    },
    id_refund_reason_gift(id_refund_reason_gift) {
      this.id_refund_reason_gift = id_refund_reason_gift;
      this.updateProductRefundReason({ 
        id_refund_reason_gift: id_refund_reason_gift 
      });
    }
  },
  computed: {
    ...mapGetters('order', [
      'order'
    ]),
    ...mapGetters('refund', [
      'refund', 
      'loading', 
      'error'
    ]),
    ...mapGetters('states', [
      'states'
    ]),
    displayCreateRefundRequestButton() {
      return this.refund.id_last_state == 0 && this.grandTotal > 0;
    },
    displayUpdateRefundRequestButton() {
      return this.refund.id_last_state == 1 && this.grandTotal > 0;
    },
    displayValidateRefundButton() {
      return this.refund.id_last_state == 1 && this.grandTotal > 0;
    },
    grandTotal() {
      const products = (this.refund.products || []).reduce((total, product) => {
        const amount = product.product_amount || 0;
        const quantity = product.qty || 1;
        return total + (amount * quantity);
      }, 0);

      const shippings = this.refund.shipping_amount || 0;
      const fees = this.refund.fees_amount || 0;
      const gift = this.refund.gift_amount || 0;

      return products + shippings + fees + gift;
    }
  },
  methods: {
    ...mapActions('refund', [
      'fetchRefundRequest', 
      'initRefundRequest', 
      'createRefundRequest',
      'updateRefundRequest',
      'addProduct',
    ]),
    ...mapActions('states', [
      'fetchRefundStates',
    ]),
    capitalizeEachWord, 
    formatDate, 
    horizontalScroll, 
    closeWorkplan() {
      this.$emit('close-workplan');
    },
    displayRefund(refund) {
      switch (refund) {
        case 'shipping': this.displayRefundShipping = true; break;
        case 'fees': this.displayRefundFees = true; break;
        case 'gift': this.displayRefundGift = true; break;
      }
    }
  },
  mounted() {
    if (!this.states.refunds) {
      this.fetchRefundStates();
    }
  }
}
</script>
