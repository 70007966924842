export default {
  namespaced: true,
  state: {
    alert: {
      show: false,
      style: '',
      message: '',
    },
  },
  mutations: {
    SET_ALERT(state, { show, style, message }) {
      state.alert.show = show;
      state.alert.style = style;
      state.alert.message = message;
    },
    CLEAR_ALERT(state) {
      state.alert.show = false;
      state.alert.style = '';
      state.alert.message = '';
    }
  },
  actions: {
    setAlert({ commit }, { style, message }) {
      commit('SET_ALERT', { show: true, style, message });
    },
    clearAlert({ commit }) {
      commit('CLEAR_ALERT');
    }
  },
  getters: {
    alert: state => state.alert,
  }
};
