<template>
  <div class="modal fade" id="addCustomerModal" tabindex="-1" aria-labelledby="addCustomerModal" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title" id="addCustomerModal">
            {{ step == 'search' ? 'Chercher un client' : 'Créer un nouveau client' }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <!-- Chercher un client -->
        <div v-if="step == 'search'" class="modal-body">
          <div class="row">
            <search-bar :keywords="keywords" placeholder="Tapez un nom ou une adresse e-mail ..." @search="onSearch" />
          </div>
          <div v-if="search.loading" class="row p-3 pb-0">
            <loader />
          </div>
          <div v-else-if="search.customers.length" class="row p-3 pb-0">
            <table class="table table-striped text-start align-middle">
              <thead>
                <tr>
                  <th>Prénom</th>
                  <th>Nom</th>
                  <th>Adresse e-mail</th>
                  <th>Date de naissance</th>
                  <th>Code postal</th>
                  <th class="text-center">Ajouter</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(customer, index) in search.customers" :key="index">
                  <td>{{ customer.firstname }}</td>
                  <td>{{ customer.lastname }}</td>
                  <td>{{ customer.email }}</td>
                  <td>{{ customer.dob }}</td>
                  <td v-if="customer.addresses && customer.addresses.length > 0">{{ customer.addresses[0].postcode }}</td>
                  <td v-else>-</td>
                  <td class="text-center">
                    <button type="button" class="btn btn-success btn-sm" @click="addCustomer(customer)" data-bs-dismiss="modal">Ajouter</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else-if="keywords" class="row p-3 pb-0">
            <div class="col-12 text-center">Aucun client n'a été trouvé.</div>
          </div>
        </div>

        <!-- Créer un nouveau client -->
        <div v-else-if="step == 'create'" class="modal-body">
          <div v-if="customer && customer.error" class="row g-2">
            <div class="col-12">
              <div class="alert alert-warning text-start" role="alert">{{ customer.error }}</div>
            </div>
          </div>
          <div v-else-if="customer && customer.success" class="row g-2">
            <div class="col-12">
              <div class="alert alert-success text-start" role="alert">{{ customer.success }}</div>
            </div>
          </div>
          <div v-if="customer.loading" class="row g-2">
            <div class="col-12">
              <loader />            
            </div>
          </div>
          <form v-else class="row g-2 align-items-center" @submit.prevent="onCreate" autocomplete="off">
            <div class="row g-2 align-items-center">
              <div class="col">
                <input v-model="form.firstname" type="text" class="form-control" placeholder="Prénom" aria-label="Prénom" required>
              </div>
              <div class="col">
                <input v-model="form.lastname" type="text" class="form-control" placeholder="Nom" aria-label="Nom" required>
              </div>
            </div>
            <div class="row g-2 mb-3">
              <div class="col">
                <input v-model="form.email" type="email" class="form-control" placeholder="Adresse e-mail" aria-label="Adresse e-mail" required>
              </div>
              <div class="col d-flex align-items-center">
                <input v-model="form.newsletter" type="checkbox" class="form-check-input m-0" checked>
                <label class="form-check-label mx-1">
                  Recevoir la Newsletter
                </label>          
              </div>
            </div>
            <label class="form-label">Adresse principale (optionnel)</label>
            <div class="row g-2">
              <div class="col">
                <input v-model="form.address.street[0]" type="text" class="form-control" placeholder="Numéro et rue" aria-label="Adresse">
              </div>
            </div>
            <div class="row g-2">
              <div class="col">
                <input v-model="form.address.street[1]" type="text" class="form-control" placeholder="Appartement, batiment, étage ..." aria-label="Adresse 2">
              </div>
            </div>
            <div class="row g-2">
              <div class="col">
                <input v-model="form.address.postcode" type="text" class="form-control" placeholder="Code postal" aria-label="Code postal">
              </div>
              <div class="col">
                <input v-model="form.address.city" type="text" class="form-control" placeholder="Ville" aria-label="Ville">
              </div>
            </div>
            <div class="row g-2">
              <div class="col">
                <select v-model="form.address.region" class="form-control" aria-label="Région">
                  <option v-for="region in regions" :key="region.region_id" :value="region">
                    {{ region.default_name }}
                  </option>
                </select> 
              </div>
              <div class="col">
                <select v-model="form.address.country_id" class="form-control" aria-label="Pays"> 
                  <option v-for="country in countries" :key="country.country_id" :value="country.country_id">
                    {{ country.country_id }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row g-2">
              <div class="col">
                <input v-model="form.address.telephone" type="tel" class="form-control" placeholder="Téléphone" aria-label="Téléphone">
              </div>
              <div class="col text-end">
                <button type="submit" class="btn btn-primary">Valider</button>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button v-if="step == 'search'" type="button" class="btn btn-secondary" @click="step = 'create'">Créer un nouveau client</button>
          <button v-if="step == 'create'" type="button" class="btn btn-secondary" @click="step = 'search'">Retour à la recherche</button>
          <button v-if="close" type="button" class="btn btn-success" data-bs-dismiss="modal" aria-label="Close">Retour au panier</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SearchBar from '@/components/common/SearchBar';

export default {
  components: {
    SearchBar,
  },
  data() {
    return {
      close: false,
      step: 'search',
      keywords: '',
      form: {
        firstname: null,
        lastname: null,
        email: null,
        newsletter: true,
        address: {
          street: [],
          postcode: null,
          city: null,
          region: {
            region_id: null,
            code: null,
            default_name: null,
          },
          country_id: 'FR',
          telephone: null,
        }
      }
    };
  },
  computed: {
    ...mapGetters('address', ['countries', 'regions']),
    ...mapGetters('customer', ['customer']),
    ...mapGetters('search', ['search']),
  },
  watch: {
    'form.address.postcode'(newPostcode) {
      if (newPostcode && newPostcode.length >= 2) {
        this.setRegion(newPostcode);
      }
    }
  },
  methods: {
    ...mapActions('address', ['fetchCountries', 'fetchRegions']),
    ...mapActions('customer', ['createCustomer', 'clearCustomer']),
    ...mapActions('search', ['fetchCustomers', 'clearSearch']),
    setRegion(postcode) {
      let regionCode = postcode.substring(0, 2);
      if (regionCode.startsWith('0')) {
        regionCode = regionCode.substring(1);
      }
      const matchedRegion = this.regions.find(region => region.code == regionCode);
      if (matchedRegion) {
        this.form.address.region = matchedRegion;
      } else {
        this.form.address.region = {
          region_id: null,
          code: null,
          default_name: null,
        };
      }
    },
    onSearch(keywords) {
      this.keywords = keywords;
      const filters = {
        name: { value: keywords, condition: 'like' },
        email: { value: keywords, condition: 'like' },
      };
      const operation = 'or';
      const limit = 10;
      this.fetchCustomers({ filters, operation, limit });
    },
    async onCreate() {
      const response = await this.createCustomer(this.form);
      if (response && response.success) {
        this.addCustomer(response.success.results);
        await this.clearCustomer();
          this.customer.success = 'Client créé et ajouté avec succès.'
          this.customer.loading = false;
          this.close = true;
      }
    },
    addCustomer(customer) {
      this.$emit('add-customer', customer);
    },
  },
  mounted() {
    this.fetchCountries();
    this.fetchRegions(this.form.address.country_id);
  },
  unmounted() {
    this.clearSearch();
  }
};
</script>
