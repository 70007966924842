<template>
  <div class="row h-100 overflow-auto">
    <div class="col-12 h-100 overflow-auto">

      <div v-if="product && product.error" class="row h-100">
        <div class="row justify-content-center align-items-center">
          {{ error }}
        </div>
      </div>

      <div v-else-if="product" class="row h-100 overflow-auto">
        <div class="col-xxl-3 col-12 bg-light h-100 overflow-auto border-end">

          <div class="row align-items-center justify-content-space-between mb-4">
            <div class="col-3 p-3">
              <img v-if="product && product.media_gallery_entries && product.media_gallery_entries.length > 0" class="img-fluid border" :src="productImageUrl(product.media_gallery_entries[0].file)" alt="Product Image">
            </div>
            <div class="col-9 fs-5 fw-bold">{{ product.name }}</div>
          </div>

          <div class="row justify-content-center mt-4">
            <div class="col-auto h5 fw-bold">Informations</div>
          </div>

          <div class="row justify-content-center mb-4">
            <div class="col-12">
              <table class="table border align-middle fs-sm">
                <tbody>
                  <tr>
                    <td class="text-start">Marque</td>
                    <td class="text-end"></td>
                  </tr>
                  <tr>
                    <td class="text-start">Fournisseur</td>
                    <td class="text-end"></td>
                  </tr>
                  <tr>
                    <td class="text-start">SKU</td>
                    <td class="text-end">{{ product.sku }}</td>
                  </tr>
                  <tr>
                    <td class="text-start">Code Barre</td>
                    <td class="text-end"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row justify-content-center mt-4">
            <div class="col-auto h5 fw-bold">Commandes en attentes</div>
          </div>

          <div class="row justify-content-center mb-4">
            <div class="col-12">
              <table class="table border align-middle fs-sm">
                <tbody>
                  <tr>
                    <td class="text-start"></td>
                    <td class="text-end"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <div class="col-xxl-3 col-4 d-flex flex-column h-100 overflow-auto border-end">
          <div class="row d-flex align-content-center align-items-center justify-content-between border-bottom h-100">
            <div class="col-12 d-flex flex-column h-100">

              <!-- Liste des boutiques -->
              <div v-if="stores" class="row flex-grow-1 overflow-y-auto" style="min-height: 0;">
                <div class="col-12">
                  <div v-for="(store, index) in stores" class="row order-product text-start border-bottom" :key="index">
                    <div class="col-12">
                      <div class="row h-64">
                        {{ store.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-xxl-6 col-8 d-flex flex-column justify-content-flex-start h-100 overflow-auto ">
          Tabs
        </div>

      </div>

      <div v-else class="row h-100 overflow-auto">
        <loader />
      </div>

    </div>
  </div>
</template>

<script>
import { productImageUrl } from '@/utils/tools';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {

  },
  props: {
    product_sku: {
      type: String,
      required: true,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters('product', [
      'product',
    ]),
    ...mapGetters('store', [
      'stores',
    ]),
  },
  watch: {
    product_sku: 'fetchProduct',
  },
  methods: {
    ...mapActions('product', [
      'fetchProduct',
      'clearProduct',
    ]),
    ...mapActions('store', [
      'fetchStores',
    ]),
    productImageUrl,
  },
  mounted() {
    this.fetchProduct(this.product_sku);
    this.fetchStores();
  },
  unmounted() {
    this.clearProduct();
  }
};
</script>
