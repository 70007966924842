import returnService from '@/services/returnService';

export default {
  namespaced: true,
  state: {
    loading: false,
    error: null,
    rma: {},
  },
  mutations: {
    SET_RETURN(state, rma) {
      state.rma = rma;
    },
    SET_RETURN_STATES(state, states) {
      state.rma = { ...state.rma, states };
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
    CLEAR_RETURN(state) {
      state.rma = {
        request_items: [],
      };
    },
  },
  actions: {
    async fetchReturn({ commit }, return_id) {
      try {
        commit('SET_LOADING', true);
        commit('SET_RETURN', await returnService.getReturn(return_id));
        commit('SET_RETURN_STATES', await returnService.getStates());
        commit('SET_LOADING', false);
      } catch (error) {
        console.error(error);
      }
    },
    async clearReturn({ commit }) {
      commit('CLEAR_RETURN');
    },
    async initReturn({ commit }, { order, items }) {
      try {

        const request_items = (items && Array.isArray(items)) ? items.map(product => ({
          qty: product.qty_shipped || 0,
          reason_id: 0,
          condition_id: 0,
          resolution_id: 0,
          item_status: 0,
          ...product, 
        })) : [];

        const request  = {
          order_id: order.entity_id,
          store_id: order.store_id,
          status: 0,
          customer_name: order.customer_firstname,
          request_items: request_items || [],
        };

        commit('SET_RETURN', request);
        commit('SET_RETURN_STATES', await returnService.getStates());

      } catch (error) {
        console.error(error);
      }
    },

    async createReturn({ dispatch, commit, rootState: { order: { order }}}, request) {
      try {

        commit('SET_LOADING', true);

        const request_items = request.request_items.map(product => ({
          order_item_id: product.item_id,
          qty: product.qty || 0,
          reason_id: product.reason_id || 1,
          condition_id: product.condition_id || 1,
          resolution_id: product.resolution_id || 1,
        }));


        delete request.states;
        const response = await returnService.createReturn(order.entity_id, {
          request: {
          ...request,
          request_items: request_items,
        }});

        dispatch('order/fetchOrderReturns', order.entity_id, { root: true });

        if (response && response.success) {
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }

      } catch (error) {
        console.error(error);
      }
    },
    async addProduct({ state }, event) {
      const items = (event.items && event.items.length > 0) ? event.items : [event.item];
      items.forEach(() => {
        state.rma.request_items.forEach(product => {
          product.request_item_id = product.item_id,
          product.qty = product.qty_shipped;
          product.reason_id = 0;
          product.condition_id = 0;
          product.resolution_id = 0;
          product.new = true;
        });
      });
    },
    async updateProductReason({ state }, { request_item_id, reason_id}) {
      const product = state.rma.request_items.find(product => product.request_item_id == request_item_id);
      if (product) {
        product.reason_id = reason_id;
      }
    },
    async updateProductCondition({ state }, { request_item_id, condition_id}) {
      const product = state.rma.request_items.find(product => product.request_item_id == request_item_id);
      if (product) {
        product.condition_id = condition_id;
      }
    },
    async updateProductResolution({ state }, { request_item_id, resolution_id}) {
      const product = state.rma.request_items.find(product => product.request_item_id == request_item_id);
      if (product) {
        product.resolution_id = resolution_id;
      }
    },
    async generateLabels({ state, dispatch, rootState: { order: { order } }}) {
      try {

        const response = await returnService.createLabels(state.rma.request_id, {
          order: {
            entity_id: order.entity_id,
            customer_email: order.customer_email,
            customer_id: order.customer_id || 0,
          },
          address: order.last_shipping_assignment.shipping.address
        });

        dispatch('order/fetchOrderReturns', order.entity_id, { root: true });

        if (response && response.success) {
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }

      } catch (error) {
        console.error(error);
      }
    }
  }, 
  getters: {
    rma: state => state.rma,
    loading: state => state.loading,
    error: state => state.error,
  },
};
 