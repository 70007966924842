export const shortcuts = [
  {
    text: 'Aujourd\'hui',
    onClick() {
      const today = new Date();
      return [today, today];
    }
  },
  {
    text: 'Hier',
    onClick() {
      const start = new Date();
      start.setDate(start.getDate() - 1);
      const end = new Date();
      end.setDate(end.getDate() - 1);
      return [start, end];
    }
  },
  {
    text: 'Les 7 derniers jours',
    onClick() {
      const start = new Date();
      start.setDate(start.getDate() - 7);
      const end = new Date();
      return [start, end];
    }
  },
  {
    text: 'Cette semaine',
    onClick() {
      const now = new Date();
      const start = new Date(now.setDate(now.getDate() - now.getDay() + 1)); 
      const end = new Date();
      end.setDate(start.getDate() + 6);
      return [start, end];
    }
  },
  {
    text: 'La semaine dernière',
    onClick() {
      const now = new Date();
      const start = new Date(now.setDate(now.getDate() - now.getDay() - 6));
      const end = new Date(now.setDate(start.getDate() + 6));
      return [start, end];
    }
  },
  {
    text: 'Ce mois-ci',
    onClick() {
      const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      const end = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
      return [start, end];
    }
  },
  {
    text: 'Le mois dernier',
    onClick() {
      const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      const end = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
      return [start, end];
    }
  },
  {
    text: 'Les 3 derniers mois',
    onClick() {
      const now = new Date();
      const start = new Date(now.getFullYear(), now.getMonth() - 3, 1);
      const end = new Date(now.getFullYear(), now.getMonth(), 0);
      return [start, end];
    }
  },
  {
    text: 'Les 6 derniers mois',
    onClick() {
      const now = new Date();
      const start = new Date(now.getFullYear(), now.getMonth() - 6, 1);
      const end = new Date(now.getFullYear(), now.getMonth(), 0);
      return [start, end];
    }
  },
  {
    text: 'Cette année',
    onClick() {
      const start = new Date(new Date().getFullYear(), 0, 1);
      const end = new Date(new Date().getFullYear(), 11, 31);
      return [start, end];
    }
  },
  {
    text: 'L\'année dernière',
    onClick() {
      const start = new Date(new Date().getFullYear() - 1, 0, 1);
      const end = new Date(new Date().getFullYear() - 1, 11, 31);
      return [start, end];
    }
  }
];