import backend from './backendService';

class ProductService {
  async getProduct(sku) {
    return backend.get(`/products/${sku}`);
  }

  async getProductStockInStore(sku, source_code) {
    return backend.get(`/products/${sku}/stocks/${source_code}`);
  }

  async updateStock(sku, payload) {
    return backend.post(`/products/${sku}/stocks`, payload);
  }
}

export default new ProductService();
