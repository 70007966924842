<template>
  <div class="row bg-light h-100 overflow-y-auto">
    <div class="col-12 overflow-y-auto px-5 pt-3">
      <div class="row mb-3">

        <div class="col-12 col-xl-6">
          <h2 class="mb-3">Créer un utilisateur</h2>
          <form @submit.prevent="onCreateUser">
            <div class="row mb-3">
              <label for="firstname" class="col-sm-2 col-form-label">Prénom</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="firstname" v-model="firstname" required>
              </div>
            </div>
            <div class="row mb-3">
              <label for="lastname" class="col-sm-2 col-form-label">Nom</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="lastname" v-model="lastname" required>
              </div>
            </div>
            <div class="row mb-3">
              <label for="email" class="col-sm-2 col-form-label">E-mail</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="email" v-model="email" required>
              </div>
            </div>
            <div class="row mb-3">
              <label for="password" class="col-sm-2 col-form-label">Mot de passe</label>
              <div class="col-sm-10">
                <input type="password" class="form-control" id="password" v-model="password" required>
              </div>
            </div>

            <div class="row mb-3">
              <label for="profile" class="col-sm-2 col-form-label">Equipe</label>
              <div class="col-sm-10">
                <select class="form-select" id="profile" v-model="profile" required>
                  <option disabled value="" selected></option>
                  <option v-for="(profile, index) in profiles" :key="index" :value="profile.id_profile">{{ profile.name }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <label for="profile" class="col-sm-2 col-form-label">Rôle</label>
              <div class="col-sm-10">
                <select class="form-select" id="role" v-model="role" required>
                  <option disabled value=""></option>
                  <option v-for="(role, index) in roles" :key="index" :value="role.id_role">{{ role.name }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <label for="stores" class="col-sm-2 col-form-label">Boutiques</label>
              <div class="col-sm-10">
                <multiselect
                  v-model="user_stores"
                  :options="stores"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Sélectionnez une ou plusieurs boutiques"
                  label="name"
                  track-by="id_store">
                </multiselect>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 text-end">
                <button type="submit" class="btn btn-primary">Créer</button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      profile: '',
      role: '',
      user_stores: [],
      manager:'',
    };
  },
  components: { 
    Multiselect,
  },
  computed: {
    ...mapGetters('auth', ['profiles', 'roles']),
    ...mapGetters('store', ['stores']),
    ...mapGetters('user', ['user']),
  },
  methods: {
    ...mapActions('auth', ['fetchProfiles', 'fetchRoles']),
    ...mapActions('store', ['fetchStores']),
    ...mapActions('user', ['createUser']),
    async onCreateUser() {
      try {
        const user = {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          password: this.password,
          manager: this.user.id_user,
          profile: this.profile,
          role: this.role,
          stores: this.user_stores
        };
        
        await this.createUser(user);
        this.$router.push({ name: 'UserProfile' });

      } catch (error) {
        console.error('Erreur lors de la création de l’utilisateur:', error);
      }
    }
  },
  async mounted() {
    await this.fetchProfiles();
    await this.fetchRoles();
    await this.fetchStores();
  }
};
</script>