<template>
  <div class="modal fade" id="addCouponModal" tabindex="-1" aria-labelledby="addCouponModal" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addCouponModal">Ajouter un code promotionnel</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="row g-3 align-items-center" @submit.prevent="enterSubmit">
            <div class="col-9">
              <input v-model="code" type="text" class="form-control" placeholder="Entrez un code promo" ref="focus" autofocus required>
            </div>
            <div class="col-3">
              <button type="submit" class="btn btn-primary w-100" data-bs-dismiss="modal">Ajouter</button>
            </div>
          </form>
          <div v-if="rules && rules.length > 0" class="mt-3">
            <h5>Codes de réduction disponibles</h5>
            <ul class="list-group">
              <li v-for="rule in rules" :key="rule.rule_id" class="list-group-item">
                <div>
                  <strong>{{ rule.name }}</strong> - {{ rule.description }}
                  <li v-for="coupon in rule.coupons" :key="coupon.coupon_id" class="list-group-item d-flex justify-content-between align-items-center">
                    <span>{{ coupon.code }}</span>
                    <button class="btn btn-success btn-sm" @click="addCoupon(coupon.code)" data-bs-dismiss="modal">Ajouter</button>
                  </li>
                </div>
              </li>
            </ul>
          </div>
          <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { round } from '@/utils/tools';
import searchService from '@/services/searchService';

export default {
  props: {
    cart: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: [],
      code: '',
      error: '',
    };
  },
  methods: {
    round,
    async fetchSalesRules() {
      const rules = await searchService.searchSalesRules({ is_active: { value: 1 }});
      for (const rule of rules) {
        rule.coupons = await searchService.searchCoupons({ rule_id: { value: rule.rule_id }});
      } this.rules = rules;
    },
    async enterSubmit() {
      if (this.code.trim() != '') {
        await this.addCoupon(this.code.trim());
      }
    },
    async addCoupon(coupon_code) {
      this.$emit('add-coupon', coupon_code);
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.focus.focus();
      });
    }
  },
  mounted() {
    this.focusInput();
  },
};
</script>