<template>
  <div v-if="data" class="row align-items-center border">
    <div class="col d-flex align-items-center fw-bold lh-sm">{{ data.name }}</div>
    <div class="col-2 square d-flex justify-content-center align-items-center fw-bold border" :class="colorShipped(product)">
      <div v-if="!editQuantity">
        <span @click="startEditing">{{ product.quantity_sent }}/{{ product.quantity_to_send }}</span>
      </div>
      <div v-else>
        <input type="text" v-model.number="editableQuantity" @blur="stopEditing" @keyup.enter="stopEditing" class="form-control form-control-sm text-center" />
      </div>
    </div>
  </div>
  <div v-else class="row h-120 align-items-center">
    <div class="col-12">
      <loader/>
    </div>
  </div>
</template>

<script>
import productService from '@/services/productService';

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      data: null,
      editQuantity: false,
      editableQuantity: this.product.quantity_to_send,
    };
  },
  watch: {
    product: {
      handler(value) {
        this.editableQuantity = value.quantity_to_send;
      },
      deep: true,
    }
  },
  methods: {
    colorShipped(product) {
      if (product.quantity_sent < product.quantity_to_send) {
        return 'border-warning bg-warning-subtle';
      } else if (product.quantity_sent == 0 && product.quantity_to_send == 0) {
        return 'border-secondary bg-secondary-subtle';
      } else if (product.quantity_sent == product.quantity_to_send) {
        return 'border-success bg-success-subtle';
      } else if (product.quantity_sent > product.quantity_to_send) {
        return 'border-danger bg-danger-subtle';
      }
    },
    startEditing() {
      this.editQuantity = true;
    },
    stopEditing() {
      this.editQuantity = false;
      this.updateQuantity();
    },
    updateQuantity() {
      console.log('coucou')
    }
  },
  async mounted() {
    this.data = await productService.getProduct(this.product.sku);
  },
}
</script>