import authService from '@/services/authService';
import userService from '@/services/userService';

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || null,
    user: JSON.parse(localStorage.getItem('user')) || { stores: [], employees: [] },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    SET_STORES(state, stores) {
      state.user = { ...state.user, stores };
    },
    SET_PROFILES(state, profiles) {
      state.user = { ...state.user, profiles };
    },
    SET_EMPLOYEES(state, employees) {
      state.user = { ...state.user, employees };
    },
    LOGOUT(state) {
      state.token = null;
      state.user = { stores: [], employees: [] };
      localStorage.removeItem('token');
    },
  },
  actions: {
    async login({ commit, dispatch }, { email, password }) {
      try {
        const response = await authService.login(email, password);
        commit('SET_TOKEN', response.token);
        await dispatch('fetchUser');
      } catch (error) {
        console.error('Erreur dans login:', error);
        throw error;
      }
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
    async createUser({ dispatch }, user) {
      try {
        await userService.createUser(user);
        dispatch('fetchUser');
      } catch (error) {
        console.error(error);
      }
    },
    async fetchUser({ commit, dispatch }) {
      try {
        commit('SET_USER', await userService.getUser());
      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: 'Failed fetchUser: ' + error }, { root: true });
      }
    },
    async fetchUserProfiles({ commit, dispatch }) {
      try {
        commit('SET_PROFILES', await userService.getUserProfiles());
      } catch (error) {
        dispatch('loader/error', `Failed to fetch user profiles: ${error.message}`, { root: true });
      }
    },
    async fetchUserStores({ commit, dispatch }) {
      try {
        commit('SET_STORES', await userService.getUserStores());
      } catch (error) {
        dispatch('loader/error', `Failed to fetch user stores: ${error.message}`, { root: true });
      }
    },
    async fetchUserEmployees({ commit, dispatch }) {
      try {
        commit('SET_EMPLOYEES', await userService.getUserEmployees());
      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: 'Failed fetchUserEmployees: ' + error }, { root: true });
      }
    },
    async updateUserProfile({ dispatch }, user) {
      console.log(user)
      try {
        await userService.updateUserProfile(user);
          dispatch('alerts/setAlert', { style: 'alert-success', message: 'Profil mis à jour avec succès' }, { root: true });
          dispatch('fetchUser');
      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: 'Failed updateUserDefaultStore: ' + error }, { root: true });
      }
    },
    async updateUserDefaultStore({ dispatch }, store) {
      try {
        await userService.updateUserDefaultStore(store);
          dispatch('alerts/setAlert', { style: 'alert-success', message: 'Boutique modifiée avec succès : ' + store.name}, { root: true });
          dispatch('fetchUser');
      } catch (error) {
        dispatch('alerts/setAlert', { style: 'alert-danger', message: 'Failed updateUserDefaultStore: ' + error }, { root: true });
      }
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
    user: state => state.user,
    stores: state => state.user.stores,
    token: state => state.token,
  }
};
