<template>
  <div class="row h-auto bg-light">
    <div class="col-12 h-auto px-5 pt-5">
      <ul class="nav nav-underline">
        <li class="nav-item me-3">
          <router-link :to="{ name: 'UserProfile' }" class="nav-link pt-0" :class="{ 'active': isActive('me'), 'text-body-tertiary': !isActive('me') }">
            <h1>Mon profil</h1>
          </router-link>
        </li>
        <li class="nav-item me-3">
          <router-link :to="{ name: 'UserCreate' }" class="nav-link pt-0" :class="{ 'active': isActive('create'), 'text-body-tertiary': !isActive('create') }">
            <h1>Administrateur</h1>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <router-view />
</template>

<script>
export default {
  methods: {
    isActive(tab) {
      const page = this.$route.path;
      return page.includes(tab);
    }
  }
};
</script>