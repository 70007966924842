import backend from './backendService';

class PreparationService {
  async getPreparation(preparation_id) {
    return backend.get(`/preparations/${preparation_id}`);
  }

  async getShipment(shipment_id) {
    return backend.get(`/shipments/${shipment_id}`);
  }

  async createPreparation(payload) {
    return backend.post(`/preparations`, payload);
  }

  async createShipment(order_id, payload) {
    return backend.post(`/orders/${order_id}/ship`, payload);
  }
}

export default new PreparationService();
