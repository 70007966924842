<template>
  <form class="d-flex align-items-center w-100" @submit.prevent="onSearch">
    <input 
      v-model="localKeywords" 
      :placeholder="placeholder" 
      class="form-control me-2" 
      type="text" 
      ref="focus" 
      autocomplete="off" />
    <button type="submit" class="btn btn-primary">Chercher</button>
  </form>
</template>

<script>
import { focus } from '@/directives';

export default {
  directives: {
    focus,
  },
  props: {
    keywords: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Entrez votre recherche...',
    },
  },
  data() {
    return {
      localKeywords: this.keywords,
    };
  },
  watch: {
    keywords(newKeywords) {
      this.localKeywords = newKeywords;
    },
  },
  methods: {
    onSearch() {
      this.$emit('search', this.localKeywords);
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.focus.focus();
      });
    }
  },
  mounted() {
    this.focusInput();
  }
};
</script>

