<template>
  <div class="col-12">
    <div class="row h-120">
      <div class="col-2 max-1260 d-flex align-items-center p-0 mx-2">
        <img v-if="data && data.media_gallery_entries && data.media_gallery_entries.length > 0" class="img-fluid border" :src="productImageUrl(data.media_gallery_entries[0].file)" alt="Product Image">
        <img v-else class="img-fluid border" :src="productImageUrl('/placeholder/websites/1/img_placeholder_base.jpg')" alt="Product Image">
      </div>
      <div class="col d-flex flex-column h-100 ps-0">
        <div class="row align-items-center">
          <div class="col d-flex align-items-center fw-bold lh-sm">{{ product.name }}</div>
          <div class="col-2 square d-flex justify-content-center align-items-center fw-bold border" :class="colorShipped(product)">
            {{ product.qty_shipped }}/{{ product.qty_invoiced }}
          </div>
        </div>
        <div class="row flex-grow-1 align-items-center">
          <div class="col">
            <div class="row d-flex flex-column">
              <div class="col fw-light fs-sm">SKU : {{ product.sku }}</div>
              <div class="col fw-light fs-sm">Fournisseur : {{ productSupplier }}</div>
              <!-- <div class="col fw-light fs-sm">Quantité commandé : {{ product.qty_ordered }}</div> -->
            </div>
            <div class="row">
              <div v-if="data && data.extension_attributes" class="col-8 fw-light fs-sm">Stock : {{ data.extension_attributes.stock_item.qty }}</div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row align-items-center">
              <div class="col text-end fw-bold">{{ round(product.row_total_incl_tax,2) }} €</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { round, productImageUrl } from '@/utils/tools';
import productService from '@/services/productService';

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: null
    };
  },
  computed: {
    productBrand() {
      return this.data?.custom_attributes.find(attr => attr.attribute_code == 'brand')?.value || 'N/A';
    },
    productSupplier() {
      return this.data?.custom_attributes.find(attr => attr.attribute_code == 'supplier')?.value || 'N/A';
    }
  },
  methods: {
    round, productImageUrl,
    colorShipped(product) {
      if (product.qty_shipped < (product.qty_invoiced)) {
        return 'border-warning bg-warning-subtle';
      } else if (product.qty_shipped == 0 && product.qty_invoiced == 0) {
        return 'border-secondary bg-secondary-subtle';
      } else if (product.qty_shipped == product.qty_invoiced) {
        return 'border-success bg-success-subtle';
      } else if (product.qty_shipped > product.qty_invoiced) {
        return 'border-danger bg-danger-subtle';
      }
    },
  },
  async mounted() {
    this.data = await productService.getProduct(this.product.sku);
  },
}
</script>

<style scoped>
@media (max-width: 1260px) {
  .max-1260 {
    display: none;
  }
}
</style>