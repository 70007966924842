<template>
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-xl-4 col-md-6">
      <div class="card">
        <div class="card-body">
          <h1 class="card-title text-center">Login</h1>
          <form @submit.prevent="performLogin" class="d-flex flex-column justify-content-center">
            <div class="col-12 form-group mb-2">
              <input v-model="email" type="email" class="form-control" placeholder="Email" required>
            </div>
            <div class="col-12 form-group mb-3">
              <input v-model="password" type="password" class="form-control" placeholder="Password" required>
            </div>
            <button type="submit" class="btn btn-primary">Login</button>
          </form>
          <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      error: null
    };
  },
  methods: {
    ...mapActions('user', ['login']),
    async performLogin() {
      try {
        this.error = null;
        await this.login({ email: this.email, password: this.password });
        this.$router.push(this.$route.query.redirect || '/');
      } catch (error) {
        this.error = 'Login failed. Please check your credentials and try again.';
        console.error('Login error:', error);
      }
    }
  }
};
</script>