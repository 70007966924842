import backend from './backendService';

class UserService {
  async getUser() {
    return backend.get('/users/me');
  }

  async createUser(user) {
    return backend.post('/users/register', user);
  }

  async getUserProfiles() {
    return backend.get('/users/me/profiles');
  }

  async getUserStores() {
    return backend.get('/users/me/stores');
  }

  async getUserEmployees() {
    return backend.get('/users/me/employees');
  }

  async updateUserProfile(profile) {
    return backend.post('/users/me/profile', profile);
  }

  async updateUserDefaultStore(store) { 
    return backend.post('/users/me/store', store);
  }
}

export default new UserService();
