<template>
  <div class="modal fade" id="adyenPaymentModal" tabindex="-1" aria-labelledby="adyenPaymentModal" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title">Module de paiement Adyen</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <div id="component-container"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AdyenCheckout, Card } from '@adyen/adyen-web';
import '@adyen/adyen-web/styles/adyen.css';

export default {
  data() {
    return {
      checkout: null
    };
  },
  computed: {
    ...mapGetters('cart', [
      'cart'
    ]),
  },
  methods: {
    ...mapActions('cart', [
      'getAdyenPaymentMethods',
      'postAdyenPayment',
      'postAdyenPaymentDetails',

    ]),
    async initializeAdyenCheckout() {
      try {
        const paymentMethodsResponse = await this.getAdyenPaymentMethods();
        const configuration = {
          clientKey: 'test_4EL52ND56FFXFEDNMTO5TDK4MQF3QCI4',
          environment: 'test',
          amount: {
            value: this.cart.totals.grand_total * 100,
            currency: 'EUR'
          },
          locale: 'fr-FR',
          countryCode: 'FR',
          paymentMethodsResponse: paymentMethodsResponse,
          onSubmit: async (state) => {
            const results = await this.postAdyenPayment(state.data)
            if (results.resultCode == 'Authorised') {
              this.$emit('payment-completed', {
                payment_brand: results.paymentMethod.brand,
                data: JSON.stringify(state.data),
              });
            }
          },
          onAdditionalDetails: async (state) => {
            await this.postAdyenPaymentDetails(state.data);
          },
          onPaymentCompleted: (state) => {
            this.$emit('payment-completed', state.data);
          },
          onPaymentFailed: (result, component) => {
            console.info(result, component);
          },
          onError: (error, component) => {
            console.error(error.name, error.message, error.stack, component);
          }
        };

        const checkout = await AdyenCheckout(configuration);
        new Card(checkout).mount('#component-container');

      } catch (error) {
        console.error('Erreur lors de l\'initialisation du paiement :', error);
      }
    },
  },
  mounted() {
    this.initializeAdyenCheckout();
  }
};
</script>