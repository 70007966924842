<template>
  <div class="modal fade" :id="'viewProductModal-' + product.sku" tabindex="-1" aria-labelledby="viewProductModalLabel" aria-hidden="true" >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title user-select-auto" id="viewProductModalLabel">Aperçu de fiche produit</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">

          <div v-if="product.media_gallery_entries && product.media_gallery_entries.length > 0" class="row">
            <div class="col-6 h-auto">
              <img :src="productImageUrl(selectedImage)" class="img-fluid" alt="Product Image">
            </div>
            <div class="col-3 ms-auto overflow-y-auto" style="max-height: 384px">
              <img  v-for="(image, index) in product.media_gallery_entries" :key="index" 
                :src="productImageUrl(image.file)" 
                class="img-thumbnail mb-2" 
                alt="Product Thumbnail"
                @click="selectImage(image.file)"
                :class="{ 'active-thumbnail': selectedImage == image.file }"
                style="cursor: pointer;" >
            </div>
          </div>
          <div v-else class="row">
            <div class="col-12">Aucune image disponible pour ce produit.</div>
          </div>

          <div class="row mt-3">
            <div class="col user-select-auto">
              <h2>{{ product.name }}</h2>
            </div>
            <div class="col-auto ser-select-auto">
              <h2>{{ round(product.price, 2) }} €</h2>
            </div>
          </div>

          <div class="row">
            <div class="col-12 user-select-auto mt-3" v-html="description"></div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>


<script>
import { round, productImageUrl } from '@/utils/tools';

export default {
  props: {
    product: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedImage: this.product.media_gallery_entries && this.product.media_gallery_entries.length > 0 ? this.product.media_gallery_entries[0].file : '',
    };
  },
  computed: {
    description() {
      return this.getCustomAttribute('description');
    },
  },
  methods: {
    productImageUrl,
    round,
    selectImage(image) {
      this.selectedImage = image;
    },
    getCustomAttribute(attributeCode) {
      const attribute = this.product.custom_attributes.find(attr => attr.attribute_code == attributeCode);
      return attribute ? attribute.value : null;
    },
  },
};
</script>
