// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import Router from '@/router';
import Store from '@/store';
import Loader from '@/components/nav/Loader';
import Draggable from 'vuedraggable';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';

const app = createApp(App);

app.use(Router);
app.use(Store);

app.component('draggable', Draggable);
app.component('loader', Loader);

app.mount('#app');
