<template>
  <div class="modal fade" id="addProductModal" tabindex="-1" aria-labelledby="addProductModal" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="modal-title" id="addProductModal">Ajouter un produit</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">

          <div class="row">
            <search-bar :keywords="keywords" placeholder="Recherchez un produit ou scannez un code-barre..." @search="onSearch" />
          </div>

          <div v-if="search.loading" class="row p-3 pb-0">
            <loader />
          </div>

          <div v-else-if="search.products.length" class="row p-3 pb-0">
            <table class="table table-striped text-start align-middle">
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Produit</th>
                  <th class="text-end">Ajouter</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(result, index) in search.products" :key="index">
                  <td>{{ result.sku }}</td>
                  <td>{{ result.name }}</td>
                  <td class="text-end">
                    <button type="button" class="btn btn-success btn-sm" @click="addProduct(result)">Ajouter</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-else-if="keywords" class="row p-3 pb-0">
            <div class="col-12 text-center">Aucun produit n'a été trouvé.</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { round, productImageUrl } from '@/utils/tools';
import SearchBar from '@/components/common/SearchBar';

export default {
  components: {
    SearchBar,
  },
  data() {
    return {
      keywords: '',
    }
  },
  computed: {
    ...mapGetters('search', ['search']),
  },
  methods: {
    ...mapActions('search', ['fetchProducts', 'clearSearch']),
    productImageUrl, round,
    onSearch(keywords) {
      this.keywords = keywords;
      const filters = {
        ean: { value: keywords, condition: 'eq' },
        name: { value: keywords, condition: 'like' },
        sku: { value: keywords, condition: 'eq' },
        type_id: { value: 'simple', condition: 'eq' },
      };
      const operation = 'or';
      const limit = 50;
      this.fetchProducts({ filters, operation, limit });
    },
    addProduct(product) {
      this.$emit('add-product', product);
    },
  },
};
</script>
