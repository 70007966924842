<template>

  <div class="row flex-grow-1 overflow-auto">
    <div v-if="loading" class="col d-flex  justify-content-center align-items-center">
      <loader/>
    </div>
    <draggable v-else-if="cart && cart.totals" class="col" :list="cart.totals.items" :itemKey="item => item.sku" :group="{ name: 'cart-products', pull: false, put: true}" :sort="false" @add="addToCart">
      <template v-slot:item="{ element, index }">
        <article class="row cart-product text-start user-select-auto" :itemKey="index" :sku="element.sku" :price="element.price">
          <cart-product :product="element" @update-quantity="updateQuantity" @delete-product="deleteProduct" />
        </article>
      </template>
    </draggable>
  </div>

  <div class="row d-flex justify-content-between p-3 border-top bg-light fw-bold">
    <div class="col p-0">Total produit</div>
    <div v-if="cart && cart.totals" class="col p-0 d-flex justify-content-end">{{ cart.totals.subtotal_incl_tax ?  cart.totals.subtotal_incl_tax : 0 }} €</div>
  </div>

  <div v-if="cart && cart.totals" class="row d-flex p-3 border-top">
    <button class="col-auto btn btn-primary fw-bold me-3" data-bs-target="#addCouponModal" data-bs-toggle="modal">Code promo</button>
    <router-link :to="'/carts/' + cart.id + '/checkout'" class="col btn btn-primary w-100 fw-bold" :class="{ 'disabled': !cart.totals.subtotal_incl_tax }">Valider le panier</router-link>
  </div>

  <add-coupon-modal :cart="cart" @add-coupon="addCoupon" />

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CartProduct from '@/components/cart/CartProduct';
import AddCouponModal from '@/components/modals/AddCouponModal.vue';

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CartProduct,
    AddCouponModal,
  },
  computed: {
    ...mapGetters('cart', ['cart']),
  },
  methods: {
    ...mapActions('cart', ['fetchCartTotals', 'addProduct']),
    addToCart(evt) {
      this.addProduct({ 
        sku: evt.item.getAttribute('sku'), 
        price: evt.item.getAttribute('price') 
      });
    },
    updateQuantity({ item_id, qty }) {
      this.$emit('update-quantity', { 
        item_id, qty 
      });
    },
    deleteProduct(item_id) {
      this.$emit('delete-product', item_id);
    }
  },
  async mounted() {
    if (!this.cart.totals) {
      await this.fetchCartTotals();
    }
  }
}
</script>