<template>
  <div class="col d-flex flex-column h-100">
    <div class="row flex-grow-1 overflow-y-auto">
      
      <template v-if="error">{{ error }}</template>
      <template v-else-if="loading"><loader/></template>
      <template v-else>
        
        <draggable
          class="row row-cols-3 flex-grow-1 overflow-y-auto justify-content-center align-items-center align-content-center"
          :list="preparation.products"
          :group="{ name: 'preparation-products', pull: true, put: ['products'] }"
          :multi-drag="true"
          selected-class="selected"
          :sort="false"
          @add="addProduct">

          <template v-slot:item="{ element, index }">
            <article v-if="element" class="col-4 m-1" :itemKey="index" :sku="element.sku">
              <preparation-product :product="element" @click="toggleProductSelection(element)" />
            </article>
          </template>
        </draggable>

      </template>
    </div>

    <div class="row align-items-center border-top py-1 ">
      <div class="col-auto">
        <span v-if="preparation.id_preparation"><strong>Préparation #{{ preparation.id_preparation }}</strong> initiée le {{ formatDate(preparation.date_add) }}</span>
        <span v-else-if="preparation.entity_id"><strong>Remis en boutique</strong> le {{ formatDate(preparation.created_at) }}</span>
        <span v-else ><strong>Nouvelle expédition</strong></span>
      </div>
      <div class="col-auto ms-auto">
        <button class="btn btn-sm btn-danger" @click="closeWorkplan">Fermer</button>
      </div>
    </div>

    <div class="row border-top py-3 bg-light">
      <div class="col-12">
        <div class="row h-128">

          <action-card v-if="preparation.id_carrier" :title="'Transporteur'" :content="capitalizeEachWord(preparation.carrier_name)" :icon="'bi-pencil-square'" :modal="'editCarrierModal'"/>
          <action-card v-if="preparation.address" :title="'Adresse'" :content="formattedAddress" :icon="'bi-pencil-square'" class="me-auto" />

          <action-button v-if="showDeleteButton" :icon="'bi-trash'" :text="'Retirer les\nproduits'" :color="'white'" :type="'danger'" @click="deleteSelectedProducts" />
          <action-button v-if="showCancelButton" :icon="'bi-x-square'" :text="'Annuler la\npréparation'" :color="'white'" :type="'danger'" @click="cancelPreparation" />
          <action-button v-if="showCreateShipmentButton" :icon="'bi-box2-heart'" :text="'Expédition\nlogistique'" :color="'white'" :type="'primary'" @click="createPreparation(preparation)" />
          <action-button v-if="showInStoreButton" :icon="'bi-bag-check'" :text="'Remis en boutique'" :color="'white'" :type="'success'" @click="createShipment(preparation)" />

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate,capitalizeEachWord } from '@/utils/tools';
import PreparationProduct from '@/components/order/OrderPreparationProduct';
import ActionCard from '@/components/common/ActionCard';
import ActionButton from '@/components/common/ActionButton';

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      selection: [],
    };
  },
  components: {
    PreparationProduct,
    ActionCard,
    ActionButton,
  },
  computed: {
    ...mapGetters('user', ['user']),
    ...mapGetters('order', ['order']),
    ...mapGetters('preparation', ['preparation', 'loading', 'error']),
    showDeleteButton() {
      return this.selection.length > 0;
    },
    showCancelButton() {
      return this.preparation.id_preparation_state == 1;
    },
    showCreateShipmentButton() {
      return this.preparation.id_preparation_state == 0 && this.preparation.products.length > 0;
    },
    showInStoreButton() {
      return /* this.user.profile == 4 && */ this.preparation.id_preparation_state == 0 && this.preparation.products.length > 0;
    },
    formattedAddress() {
      const address = this.preparation.address;
      if (!address) return '';
      if (this.preparation.id_preparation) {
        return `${address.firstname} ${address.lastname}\n${address.address1}\n${address.address2}\n${address.postcode} ${address.city}\n${address.telephone}`;
      }
      return `${address.firstname} ${address.lastname}\n${address.street.join('\n')}\n${address.postcode} ${address.city}\n${address.telephone}`;
    }
  },
  watch: {
    data: {
      async handler(preparation) {
        if (preparation == 'create') {
          await this.initPreparation({ order: this.order });
        } else if (preparation.id_preparation) { 
          await this.fetchPreparation(preparation.id_preparation);
        } else if (preparation.entity_id) { 
          await this.fetchShipment(preparation.entity_id);
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapActions('preparation', [
      'fetchPreparation', 
      'fetchShipment',
      'initPreparation', 
      'createShipment', 
      'createPreparation', 
      'addProduct', 
      'deleteProduct', 
      'editQuantity', 
      'updatePreparation'
    ]),
    formatDate, 
    capitalizeEachWord,
    toggleProductSelection(product) {
      const index = this.selection.findIndex(p => p.sku == product.sku);
      if (index == -1) this.selection.push(product);
      else this.selection.splice(index, 1);
    },
    deleteSelectedProducts() {
      this.preparation.products = this.preparation.products.filter(product => 
        !this.selection.some(selected => selected.sku == product.sku)
      );
      this.selection = [];
    },
    closeWorkplan() {
      this.$emit('close-workplan');
    }
  }
}
</script>
