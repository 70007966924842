import orderService from '@/services/orderService';

export default {
  namespaced: true,
  state: {
    order: null,
  },
  mutations: {
    SET_ORDER(state, order) {
      state.order = order;
    },
    SET_ORDER_TABS(state, tabs) {
      state.order = { ...state.order, tabs };
    },
    SET_ACTIVE_TAB(state, activetab) {
      state.order = { ...state.order, activetab };
    },
    SET_WORKPLAN(state, workplan) {
      state.order = { ...state.order, activeplan: workplan.component };
      state.order = { ...state.order, workplan: workplan.element };
    },
    SET_ORDER_PREPARATIONS(state, preparations) {
      state.order = { ...state.order, preparations };
    },
    SET_ORDER_RETURNS(state, returns) {
      state.order = { ...state.order, returns };
    },
    SET_ORDER_REFUNDS(state, refunds) {
      state.order = { ...state.order, refunds };
    },
    SET_ORDER_INVOICES(state, invoices) {
      state.order = { ...state.order, invoices };
    },
    SET_ORDER_CARRIERS(state, carriers) {
      state.order = { ...state.order, carriers };
    },
    SET_ORDER_BADGES(state, badges) {
      state.order = { ...state.order, badges };
    },
    SET_ERROR(state, error) {
      state.order.error = error;
    },
    CLEAR_ORDER(state) {
      state.order = null;
    },
  },
  actions: {
    async fetchOrder({ commit }, order_id) {
      try {
        commit('SET_ORDER', await orderService.getOrder(order_id));
      } catch (error) {
        commit('SET_ERROR', error);
      }
    },
    async fetchOrderTabs({ commit }) {
      try {
        commit('SET_ORDER_TABS', await orderService.getOrderTabs());
      } catch (error) {
        console.error(error);
      }
    },
    async fetchOrderPreparations({ state, commit }) {
      try {
        commit('SET_ORDER_PREPARATIONS', await orderService.getOrderPreparations(state.order.entity_id));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchOrderReturns({ state, commit }) {
      try {
        commit('SET_ORDER_RETURNS', await orderService.getOrderReturns(state.order.entity_id));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchOrderRefunds({ state, commit }) {
      try {
        commit('SET_ORDER_REFUNDS', await orderService.getOrderRefunds(state.order.entity_id));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchOrderInvoices({ state, commit }) {
      try {
        commit('SET_ORDER_INVOICES', await orderService.getOrderInvoices(state.order.entity_id));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchOrderBadges({ state, commit }) {
      try {
        commit('SET_ORDER_BADGES', await orderService.getOrderBadges(state.order.entity_id));
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCarriers({ commit }) {
      try {
        commit('SET_ORDER_CARRIERS', await orderService.getCarriers());
      } catch (error) {
        console.error(error);
      }
    },
    async updateOrderStatus({ state, dispatch }, status) {
      const result = await orderService.updateOrderStatus(state.order.entity_id, { status: status });
      if (result.success) {
        dispatch('fetchOrder', state.order.entity_id);
      }
    },
    async createRefund({ state, dispatch }, { items }) {
      const result = await orderService.createRefund(state.order.entity_id, { items: items });
      if (result.success) {
        dispatch('fetchOrder', state.order.entity_id);
      }
    },
    async createInvoice({ state, dispatch }) {
      const result = await orderService.createInvoice(state.entity_id, { notifyCapture : { capture: true, notify: false }});
      if (result.success) {
        dispatch('fetchOrder', state.order.entity_id);
      }
    },
    async setActiveTab({ commit }, tab) {
      console.log(tab)
      commit('SET_ACTIVE_TAB', tab);
    },
    async setWorkplan({ commit }, workplan) {
      commit('SET_WORKPLAN', workplan); 
    },
    async clearOrder({ commit }) {
      commit('CLEAR_ORDER');
    }
  },
  getters: {
    order: state => state.order,
  }
};
