import preparationService from '@/services/preparationService';

export default {
  namespaced: true,
  state: {
    loading: false,
    error: null,
    preparation: {
      products: [],
      id_carrier: '',
      carrier_name: '',
      address: ''
    },
  },
  mutations: {
    SET_PREPARATION(state, preparation) {
      state.preparation = preparation;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
    CLEAR_PREPARATION(state) {
      state.preparation = {
        products: [],
        id_carrier: '',
        carrier_name: '',
        address: ''
      };
    },
  },
  actions: {
    async fetchPreparation({ commit }, preparation_id) {
      try {
        commit('SET_LOADING', true);
        commit('SET_PREPARATION', await preparationService.getPreparation(preparation_id));
        commit('SET_LOADING', false);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchShipment({ commit }, shipment_id) {
      try {
        commit('SET_PREPARATION', await preparationService.getShipment(shipment_id));
      } catch (error) {
        console.error(error);
      }
    },
    async initPreparation({ commit }, { order, items }) {

      let source = '1';
      switch (order.store_id){
        case 7: source = '2'; break;
        case 8: source = '2'; break;
        default: source = '1'; break;
      }

      const shipping_assignment = order.last_shipping_assignment;
      const products = (items && Array.isArray(items)) ? items.map(product => ({
        quantity_to_send: product.qty_ordered || 0,
        quantity_sent: 0,
        ...product,
      })) : [];

      const preparation = {
        source: source,
        gift: false,
        priority: 90,
        invoice: false,
        cn23: false,
        serenity_pack: '0',
        id_preparation_state: 0,
        id_order_source: String(order.entity_id),
        id_carrier: String(order.carrier.id_carrier_wms) || '',
        id_carrier_mode: String(order.carrier.id_carrier_mode_wms) || '',
        carrier_name: order.carrier.name || '',
        address: shipping_assignment.shipping.address || '',
        products: products || [],
      };
      
      commit('SET_PREPARATION', preparation);
    },
    async createPreparation({ dispatch, commit, rootState: { order: { order }}}, payload) {
      try {

        commit('SET_LOADING', true);

        const products = payload.products.map(product => ({
          price: product.price,
          sku: product.sku,
          quantity: product.quantity_to_send,
          hs_code: '9019101000',
        }));

        const street = payload.address.street || [];
        const address1 = street[0] || '';
        const address2 = street[1] || '';

        const address = {
          ...payload.address,
          iso_code: payload.address.country_id,
          phone_number: payload.address.telephone,
          address1: address1,
          address2: address2,
          company: '',
        };

        delete address.country_id;
        delete address.telephone;
        delete address.street;
        
        const response = await preparationService.createPreparation({ 
          preparations: [{
          ...payload,
          products: products,
          address: address,
        }]});

        dispatch('order/fetchOrderPreparations', order.entity_id, { root: true });

        if (response && response.success) {
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }

      } catch (error) {
        console.error(error);
      }
    },
    async createShipment({ dispatch, commit, rootState: { order: { order }}}, payload) {
      try {

        commit('SET_LOADING', true);

        const products = payload.products.map(product => ({
          order_item_id: product.item_id,
          qty: product.quantity_to_send,
        }));

        const response = await preparationService.createShipment(order.entity_id, { 
          items: products,
        });

        dispatch('alerts/setAlert', { style: 'alert-success', message: response.success.message }, { root: true });
        dispatch('order/fetchOrder', order.entity_id, { root: true });

      } catch (error) {
        console.error(error);
      }
    },
    async addProduct({ state }, event) {
      const items = (event.items && event.items.length > 0) ? event.items : [event.item];
      items.forEach((item) => {
        const sku = item.getAttribute('sku');
        if (state.preparation.products.filter(product => product.sku == sku).length > 1) {
          state.preparation.products.splice(event.newIndex, 1);
          const product = state.preparation.products.find(product => product.sku == sku);
          product.quantity_to_send += 1;
          product.quantity += 1;
        } else {
          state.preparation.products.forEach(product => {
            product.quantity_sent = 0;
            product.quantity_to_send = product.qty_ordered;
            product.quantity = product.qty_ordered;
          });
        }
      });
    },
    async updatePreparationCarrier({ state }, carrier) {
      console.log(state.preparation);
      console.log(carrier);
    }
  },
  getters: {
    preparation: state => state.preparation,
    loading: state => state.loading,
    error: state => state.error,
  },
};
