export default {
  namespaced: true,
  state: {
    loading: false,
    error: null,
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    }
  },
  actions: {
    load({ commit }, loading) {
      commit('SET_LOADING', loading);
      commit('CLEAR_ERROR');
    },
    error({ commit }, error) {
      commit('SET_ERROR', error);
      commit('SET_LOADING', false);
    },
    clear({ commit }) {
      commit('CLEAR_ERROR');
    }
  },
  getters: {
    loading: state => state.loading,
    error: state => state.error,
  }
};
