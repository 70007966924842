import backend from './backendService';

class ReturnService {

  async getRefundRequest(id_refund) {
    return backend.get(`/refunds/${id_refund}`);
  }
  
  async getRefundRequestProducts(id_refund) {
    return backend.get(`/refunds/${id_refund}/products`);
  }

  async createRefundRequest(order_id, payload) {
    return backend.post(`/orders/${order_id}/refund`, payload);
  }

  async updateRefundRequest(id_refund, payload) {
    return backend.put(`/refunds/${id_refund}`, payload);
  }

}

export default new ReturnService();
