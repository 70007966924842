<template>
  <div class="row bg-light h-100 overflow-y-auto">
    <div class="col-12 overflow-y-auto px-5 pt-3">
      <div class="row mb-3">

        <div class="col-12 col-xl-6">
          <h2 class="mb-3">Mes informations</h2>
          <form v-if="!loadingUser" @submit.prevent="updateProfile">

            <div class="row mb-3">
              <label for="firstname" class="col-sm-2 col-form-label">
                Prénom
              </label>
              <div class="col-sm-10">
                <input type="text" readonly class="form-control" id="firstname" :value="user.firstname">
              </div>
            </div>

            <div class="row mb-3">
              <label for="lastname" class="col-sm-2 col-form-label">
                Nom
              </label>
              <div class="col-sm-10">
                <input type="text" readonly class="form-control" id="lastname" :value="user.lastname">
              </div>
            </div>

            <div class="row mb-3">
              <label for="email" class="col-sm-2 col-form-label">
                E-mail
              </label>
              <div class="col-sm-10">
                <input type="text" readonly class="form-control" id="staticEmail" :value="user.email">
              </div>
            </div>

            <div class="row mb-3">
              <label for="password" class="col-sm-2 col-form-label">
                Mot de passe
              </label>
              <div class="col-sm-10">
                <input type="password" v-model="user.password" class="form-control" id="inputPassword">
              </div>
            </div>

            <div class="row mb-3">
              <label for="password" class="col-sm-2 col-form-label">
                Profil par défaut
              </label>
              <div class="col-sm-10">
                <select class="form-select" v-model="user.profile.id_profile">
                  <option v-for="(profile, index) in user.profiles" :key="index" :value="profile.id_profile">
                    {{ profile.name}}
                  </option>
                </select>              
              </div>
            </div>

            
            <div class="row mb-3">
              <label for="password" class="col-sm-2 col-form-label">
                Rôle
              </label>
              <div class="col-sm-10">
                <select class="form-select" v-model="user.id_role">
                  <option v-for="(role, index) in roles" :key="index" :value="role.id_role">
                    {{ role.name}}
                  </option>
                </select>              
              </div>
            </div>

            <div class="row mb-3">
              <label for="stores" class="col-sm-2 col-form-label">Profils</label>
              <div class="col-sm-10">
                <multiselect
                  v-model="user.profiles"
                  :options="profiles"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Sélectionnez des profiles"
                  label="name"
                  track-by="id_profile"
                  openDirection="bottom">
                </multiselect>
              </div>
            </div>

            <div class="row mb-3">
              <label for="stores" class="col-sm-2 col-form-label">Boutiques</label>
              <div class="col-sm-10">
                <multiselect
                  v-model="user.stores"
                  :options="stores"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Sélectionnez des boutiques"
                  label="name"
                  track-by="id_store"
                  openDirection="bottom">
                </multiselect>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 text-end">
                <button type="submit" class="btn btn-primary">Mettre à jour mon profil</button>
              </div>
            </div>
          </form>
          <div v-else>
            <loader/>
          </div>
        </div>

        <div class="col-12 col-xl-6">
          <h2 class="mb-3">Mes collaborateurs</h2>

          <div v-if="!loadingEmployees" class="border rounded overflow-hidden">
            <table v-if="user.employees && user.employees.length" class="table mb-0">
              <thead>
                <tr>
                  <th scope="col">Nom</th>
                  <th scope="col">E-mail</th>
                  <th scope="col">Equipe</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(employee, index) in user.employees" :key="index">
                  <td class="py-3">{{ employee.firstname }} {{ employee.lastname }}</td>
                  <td class="py-3">{{ employee.email }}</td>
                  <td class="py-3">{{ employee.profile_name }}</td>
                  <td class="py-3">{{ formatDate(employee.date_add, 'date') }}</td>
                </tr>
              </tbody>
            </table>
            <div v-else>
              <p>Aucun collaborateurs</p>
            </div>
          </div>
          <div v-else>
            <loader/>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate } from '@/utils/tools';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  data() {
    return {
      loadingUser: true,
      loadingProfiles: true,
      loadingStores: true,
      loadingEmployees: true,
    };
  },
  components: { 
    Multiselect,
  },
  computed: {
    ...mapGetters('auth', ['profiles', 'roles']),
    ...mapGetters('user', ['user']),
    ...mapGetters('store', ['stores']),
  },
  methods: {
    ...mapGetters('auth', ['fetchProfiles', 'fetchRoles']),
    ...mapActions('user', ['fetchUser', 'fetchUserProfiles', 'fetchUserStores', 'fetchUserEmployees', 'updateUserProfile']),
    ...mapActions('store', ['fetchStores']),
    formatDate,
    async updateProfile() {
      await this.updateUserProfile(this.user);
        this.fetchProfileAndStores();
    },
    async fetchProfileAndStores() {
      await this.fetchUser();
      this.loadingUser = false;

      await this.fetchUserProfiles();
        this.loadingProfiles = false;

      await this.fetchUserStores();
        this.loadingStores = false;

      await this.fetchUserEmployees();
        this.loadingEmployees = false;

      await this.fetchStores();
      await this.fetchProfiles();
      await this.fetchRoles();
    }
  },
  mounted() {
    this.fetchProfileAndStores();
  },
};
</script>
