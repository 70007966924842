<template>
  <transition name="slide-fade" appear>
    <div v-if="visible" :class="[style, 'alert', 'alert-dismissible', 'd-flex', 'align-items-center', 'position-fixed', 'shadow', 'top-0', 'start-50', 'translate-middle-x', 'mt-4', 'h-64']" role="alert" style="z-index: 1050;">
      <strong>{{ title }}</strong>&nbsp;{{ message }}
      <button type="button" class="btn-close" @click="dismiss" aria-label="Close"></button>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    },
    style: {
      type: String,
      default: 'alert-primary'
    },
    timeout: {
      type: Number,
      default: 5000
    }
  },
  data() {
    return {
      visible: true
    };
  },
  mounted() {
    this.startTimer();
  },
  computed: {
    title() {
      switch (this.style) {
        case 'alert-primary':
          return 'Information : ';
        case 'alert-success':
          return '';
        case 'alert-warning':
          return 'Attention : ';
        case 'alert-danger':
          return 'Erreur : ';
        default:
          return 'Information : ';
      }
    }
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        this.dismiss();
      }, this.timeout);
    },
    dismiss() {
      this.visible = false;
      this.$emit('dismiss');
    }
  },
  watch: {
    message(new_value, old_value) {
      if (new_value != old_value) {
        this.visible = true;
      }
    }
  }
};
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-appear-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-appear-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
